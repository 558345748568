import { Typography } from "@mui/material";
import { DateRangeType } from "../../../../../shared/reporting/api/biClient.types";
import { DimensionDescriptor } from "../../../../api/biApi.types";
import { formatDimension } from "../../../../formatters/NumberFormatter";

export const generateDisplayValueCore = (
  field: DimensionDescriptor,
  displayValues: string[] | undefined,
  itemKey: string,
  defaultEmptyValue = ""
) => {
  const item = field.dictionary.find((d) => d[field.keyFieldName] === itemKey);
  if (!item || item[field.keyFieldName] === undefined) {
    return [defaultEmptyValue];
  }
  return (displayValues || field.displayValues)
    .map((name) => item[name])
    .filter((val): val is string => val !== undefined)
    .map((value) => formatDimension(field, value));
};

export const renderDisplayValue = (
  values: (string | undefined)[],
  toolTip: string | undefined,
  styleCallback: (value: string | undefined) => JSX.Element | string | undefined
) => {
  if (values.length === 1) {
    return (
      <Typography sx={{ textDecoration: getTextDisplayValueDecoration(toolTip) }}>
        {styleCallback(values[0])}
      </Typography>
    );
  }
  return (
    <>
      <Typography
        style={{
          color: "rgba(33, 33, 33, 0.38)",
          textDecoration: getTextDisplayValueDecoration(toolTip),
        }}
      >
        {styleCallback(values[0])}&nbsp;&nbsp;
      </Typography>
      <Typography
        sx={(theme) => ({
          color: theme.palette.text.primary,
          textDecoration: getTextDisplayValueDecoration(toolTip),
        })}
      >
        {styleCallback(values[1])}
      </Typography>
    </>
  );
};

function getTextDisplayValueDecoration(toolTip: string | undefined) {
  return toolTip ? "underline dotted" : "none";
}

export const getCaptionByDateRangeType = (caption: string, dateRange: DateRangeType) => {
  switch (dateRange) {
    case DateRangeType.BeginningBalance:
      return `${caption} Beginning`;
    case DateRangeType.EndingBalance:
      return `${caption} Ending`;
    case DateRangeType.Mtd:
      return `${caption} MTD`;
    case DateRangeType.Qtd:
      return `${caption} QTD`;
    case DateRangeType.Ytd:
      return `${caption} YTD`;
    default:
      return caption;
  }
};
