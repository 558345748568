import { Box, Typography } from "@mui/material";
import { logError } from "../../../../../shared/logging";
import { AnyObject } from "../../../../../shared/types";
import { PivotRowDimensionCell, PivotRowMeasureCell } from "../../../../api/biApi.types";
import { styleCaption } from "../../../../utilities/Utilities";
import { RowField } from "../../Types";
import { DataGridTooltipWrapper } from "../../common/DataGridTooltipWrapper";
import DimensionStartIcon from "../../common/DimensionStartIcon";
import { renderDisplayValue } from "./Helper";
import { InternalColumn } from "./PreviewComponent.Types";

export function measureCell(props: { value: PivotRowMeasureCell | null | undefined }, rows: RowField[]) {
  try {
    const { value } = props;
    if (!value) return null;
    const isFieldLeaf = value.level === rows.length - 1;
    const rowField = rows[value.level];
    if (rowField) {
      return (
        <Box
          sx={(theme) => ({
            "&:hover": value?.drillDownId && { textDecoration: "underline", cursor: "pointer" },
            color: theme.palette.text.primary,
          })}
        >
          {isFieldLeaf ? <Typography>{value.formattedValue}</Typography> : styleCaption(rowField, value.formattedValue)}
        </Box>
      );
    }
    return (
      <Box sx={(theme) => ({ color: theme.palette.text.primary })}>
        <Typography>{value.formattedValue}</Typography>
      </Box>
    );
  } catch (error) {
    logError(error, "render pivot measureCell");
    return null;
  }
}

export function headerCell(props: { column: InternalColumn }) {
  try {
    const { formattedValues, toolTip } = props.column;
    return (
      <Box sx={{ display: "inline-flex", alignItems: "center", gap: 0.5, verticalAlign: "middle" }}>
        <DataGridTooltipWrapper tooltip={toolTip}>
          {renderDisplayValue(formattedValues, toolTip, (value) => value)}
        </DataGridTooltipWrapper>
      </Box>
    );
  } catch (error) {
    logError(error, "render pivot headerCell");
    return null;
  }
}

export function linesCell(props: { data: AnyObject; value: PivotRowDimensionCell }, rows: RowField[]) {
  try {
    const { value } = props;
    const rowField = rows[value.level];
    if (rowField) {
      const isFieldLeaf = value.level === rows.length - 1;
      return (
        <DataGridTooltipWrapper tooltip={value.toolTip}>
          <Box
            component={"div"}
            display={"flex"}
            sx={{ alignItems: "center", gap: 0.5, height: 18 }}
            style={{ paddingLeft: value.level * 10 + 12 }}
          >
            {value.formattedValues && <DimensionStartIcon cell={value} />}
            {renderDisplayValue(value.formattedValues, value.toolTip, (value) =>
              !isFieldLeaf ? styleCaption(rowField, value) : value
            )}
          </Box>
        </DataGridTooltipWrapper>
      );
    }
    return (
      <DataGridTooltipWrapper tooltip={value.toolTip}>
        <Box component={"div"} display={"flex"} style={{ paddingLeft: 0 * 10 + 12 }}>
          {renderDisplayValue(value.formattedValues, value.toolTip, (value) => value)}
        </Box>
      </DataGridTooltipWrapper>
    );
  } catch (error) {
    logError(error, "render pivot linesCell");
    return null;
  }
}
