import { useDispatch, useSelector } from "react-redux";
import { ClientInfo, ReportTemplate } from "../../../shared/reporting/api/biClient.types";
import { useNotificationContext } from "../../../shared/contexts/NotificationContext";
import { currentReportActions, selectReportConfiguration } from "../../store/currentReportSlice";
import { useCallback, useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import SaveTemplateDialog from "../../../shared/reporting/components/reportTemplates/dialogs/SaveTemplateDialog";
import sharedBiClient from "../../../shared/reporting/api/biClient";
import { useNavigate } from "react-router-dom";
import { getEditTemplateUrl } from "../builder/common/utilities/editReportUrl";
import { useLocalization } from "../../hooks/useLocalization";

interface Props {
  template: ReportTemplate;
  companies: ClientInfo[];
  clientInfo: ClientInfo;
}

export default function SaveTemplate({ template, companies, clientInfo }: Props) {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reportConfiguration = useSelector(selectReportConfiguration);
  const { report_templates: locale } = useLocalization();
  const [savingError, setError] = useState<string>();
  const [isSaving, setSaving] = useState(false);
  const [openNewTemplateSavingDialog, setOpenNewTemplateSavingDialog] = useState(false);

  const directSave = useCallback(() => {
    setSaving(true);
    if (reportConfiguration === undefined) return;
    const promise = sharedBiClient.updateReportTemplate({
      template: { ...template, configuration: reportConfiguration },
    });
    promise
      .then((result) => {
        if (result.success && result.data !== undefined) {
          sendNotification(locale.saved);
          dispatch(currentReportActions.setReport(result.data));
        } else {
          setError(locale.saving_error);
        }
      })
      .catch(() => {
        setError(locale.saving_error);
      })
      .finally(() => setSaving(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template, reportConfiguration]);

  useEffect(() => {
    if (savingError) {
      sendNotificationError(locale.saving_error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savingError, sendNotificationError]);

  return (
    <>
      <LoadingButton
        variant="contained"
        onClick={() => {
          if (template.reportId === undefined) {
            setOpenNewTemplateSavingDialog(true);
            return;
          }
          directSave();
        }}
        loading={isSaving}
        disabled={isSaving}
      >
        Save Template
      </LoadingButton>
      {openNewTemplateSavingDialog && (
        <SaveTemplateDialog
          title="Save Template"
          template={template}
          templateConfiguration={reportConfiguration}
          onClose={() => setOpenNewTemplateSavingDialog(false)}
          onSaved={(rt) => {
            setOpenNewTemplateSavingDialog(false);
            sendNotification(locale.saved);
            navigate(getEditTemplateUrl(rt.dataSource || clientInfo.clientCode, rt.reportId, rt.organization));
          }}
          companies={companies}
        />
      )}
    </>
  );
}
