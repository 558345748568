import { GridGroupNode } from "@mui/x-data-grid-premium";
import { TabularGeneralSettings } from "../../../../../../shared/reporting/api/biClient.types";

export const getAggregationPosition = (
  groupNode: GridGroupNode,
  settings: TabularGeneralSettings,
  groupingFields: {
    name: string;
    hideSummaries?: boolean | undefined;
  }[]
) => {
  if (groupNode.parent === null) {
    return settings.showGrandTotal ? "footer" : null;
  }
  const group = groupingFields.find((gf) => gf.name === groupNode.groupingField);
  if (group === undefined || group.hideSummaries === true) {
    return null;
  }
  return !settings.groupSummaryPlace || settings.groupSummaryPlace === "footer" ? "footer" : "inline";
};
