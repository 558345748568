import { Grid, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import HorizontalFill from "../../../../../shared/components/HorizontalFill";

type SummaryPlace = "footer" | "inline";

interface Props {
  value: SummaryPlace;
  disabled?: boolean;
  onChange: (newValue: SummaryPlace) => void;
}

export const GroupSummaryPlace = ({ value, disabled, onChange }: Props) => {
  return (
    <Grid container sx={{ flexDirection: "row", alignItems: "center" }}>
      <Typography color={(theme) => (disabled ? theme.palette.text.disabled : theme.palette.text.secondary)}>
        Show Subtotals at
      </Typography>
      <HorizontalFill />
      <ToggleButtonGroup
        exclusive
        color={disabled ? "secondary" : "primary"}
        value={value || "footer"}
        disabled={disabled}
        onChange={(_, value) => {
          if (value !== null) onChange(value);
        }}
      >
        <ToggleButton value="inline" sx={{ py: 0.3, fontWeight: 400 }}>
          Top
        </ToggleButton>
        <ToggleButton value="footer" sx={{ py: 0.3, fontWeight: 400 }}>
          Bottom
        </ToggleButton>
      </ToggleButtonGroup>
    </Grid>
  );
};

export default GroupSummaryPlace;
