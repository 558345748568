import { Box } from "@mui/material";
import DataGrid from "devextreme-react/data-grid";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import { AnyObject } from "../../../../../shared/types";
import { generateGuid } from "../../../../../shared/utilities/generateGuid";
import { PivotRowDimensionCell, PivotRowMeasureCell } from "../../../../api/biApi.types";
import useResizeObserver from "../../../../hooks/resizeObserver";
import { selectPreviewMode } from "../../../../store/currentReportSlice";
import { drillDownActions } from "../../../../store/drilldownSlice";
import PreviewComponentAlert from "../../../common/PreviewComponentAlert";
import ValidationState from "../../common/ValidationState";
import BiErrorCodeComponent from "../../common/bi-error/BiErrorCodeComponent";
import { PreviewDefaultSize } from "../../common/constants/const";
import { BuilderContext } from "../types";
import { headerCell, linesCell, measureCell } from "./PreviewComponent.Renders";
import { InternalColumn } from "./PreviewComponent.Types";
import ReportGrid from "./ReportGrid";
import { PreviewGridStateType } from "./hooks/useGridStateBuilder";

interface Props {
  state: PreviewGridStateType;
}

export default function PreviewComponent({ state }: Props) {
  const dispatch = useDispatch();
  const { rowsArea, columnsArea, valuesArea, settingsArea } = React.useContext(BuilderContext);
  const previewMode = useSelector(selectPreviewMode);

  const [dataGridInternalRef, setDataGridInternalRef] = React.useState<DataGrid | null>(null);
  const dataGridRef = React.useRef(dataGridInternalRef);
  dataGridRef.current = dataGridInternalRef;

  const divRef = React.createRef<HTMLDivElement>();
  const size = useResizeObserver(divRef, undefined, previewMode === "maximize" ? undefined : PreviewDefaultSize);

  const gridHasConfiguration = React.useMemo(() => {
    return rowsArea.values.length > 0 || columnsArea.values.length > 0;
  }, [rowsArea.values, columnsArea.values]);

  const linesCellRender = React.useCallback(
    (props: { data: AnyObject; value: PivotRowDimensionCell }) => {
      return linesCell(props, rowsArea.values);
    },
    [rowsArea.values]
  );

  const headerCellRender = React.useCallback((props: { column: InternalColumn }) => headerCell(props), []);

  const measureCellRender = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (props: any) => {
      return measureCell(props, rowsArea.values);
    },
    [rowsArea.values]
  );

  const templates = React.useMemo(() => {
    return [
      { name: "lines", render: linesCellRender },
      { name: "header", render: headerCellRender },
      { name: "measure", render: measureCellRender },
    ];
  }, [linesCellRender, headerCellRender, measureCellRender]);

  const onCellClicked = React.useCallback(
    (cell: PivotRowMeasureCell | undefined) => {
      const drillDownInfo = cell?.drillDownId;
      if (!drillDownInfo) return;

      const measure = valuesArea.values.find((f) => f.config.guid === cell.id);
      if (measure === undefined) return;
      dispatch(drillDownActions.add({ id: generateGuid(), measure, cell, onlyLedger: false, chartOfAccounts: true }));
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [valuesArea.values]
  );

  return (
    <BiErrorCodeComponent errorMessage={state.error}>
      <PreviewComponentAlert error={state.error} failedMeasures={state.failedMeasures} />
      {state.configurationValid.valid && (
        <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <Box
            ref={divRef}
            sx={(theme) => ({
              display: "flex",
              flex: 1,
              justifyContent: "center",
              position: "relative",
              fontFamily: theme.typography.h1.fontFamily,
            })}
          >
            {gridHasConfiguration && (
              <Box sx={{ display: "flex", flex: 1, position: "absolute" }}>
                <ReportGrid
                  data={state.pivot?.rows || []}
                  columns={state.columns}
                  grandTotals={state.pivot?.grandTotals}
                  templates={templates}
                  size={size}
                  showSummaries={settingsArea.settings.rowsGrandTotal}
                  onGridRef={setDataGridInternalRef}
                  onCellClicked={onCellClicked}
                />
              </Box>
            )}
            {state.calculating && <InlineLoader sx={{ zIndex: 10 }} />}
          </Box>
        </Box>
      )}
      <ValidationState state={state.configurationValid} measures={valuesArea.values} />
    </BiErrorCodeComponent>
  );
}
