import { Report, ReportTemplate, ReportType } from "../../shared/reporting/api/biClient.types";

export function getNewReport(clientCode: string): Report {
  return {
    ...getDefaultReportData(clientCode, "New Report"),
  } as Report;
}

export function getNewReportTemplate(dataSource: string, organization: string): ReportTemplate {
  return {
    ...getDefaultReportData(dataSource, "New Template"),
    status: "Draft",
    organization: organization,
    fundTypes: [],
    dataSource: dataSource,
    description: "",
  } as ReportTemplate;
}

function getDefaultReportData(clientCode: string, name: string): Report {
  return {
    name: name,
    clientCode: clientCode,
    configuration: {},
    reportType: ReportType.Tabular,
  } as Report;
}
