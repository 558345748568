import { Divider, Grid, Tab, Tabs } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { ChartConfiguration, ReportType } from "../../../../../shared/reporting/api/biClient.types";
import Visualization from "../../../common/Visualization";
import ConditionsDropFieldsContainer from "../../pivot/table/conditions/ConditionsDropFieldsContainer";
import { useFieldsStateContext } from "../contexts/FieldsStateContext";
import { useDispatch, useSelector } from "react-redux";
import { currentReportActions, selectCurrentReport } from "../../../../store/currentReportSlice";
import ArgumentDropFieldsContainer from "./arguments/ArgumentDropFieldsContainer";
import MeasuresDropFieldsContainer from "../../pivot/table/measures/MeasuresDropFieldsContainer";
import SortDropsFieldsContainer from "../../tabular/table/SortDropsFieldsContainer";
import React from "react";
import canDropValueItem from "./canDropValue";
import FormatContainer from "./FormatContainer";
import StyleContainer from "../style/StyleContainer";
import ScrollableFlexContainer from "../../../../../shared/components/ScrollableFlexContainer";

export default function TableComponent() {
  const dispatch = useDispatch();
  const report = useSelector(selectCurrentReport);
  const { conditionsArea, argumentsArea, valuesArea, sortingArea } = useFieldsStateContext();

  const [selectedTab, setSelectedTab] = useState(0);
  const valuesRef = useRef(valuesArea.values);
  valuesRef.current = valuesArea.values;

  const reportRef = useRef(report);
  reportRef.current = report;

  const availableSortFields = React.useMemo(() => {
    const result = argumentsArea.values.filter((f) => !sortingArea.values.find((v) => v.meta.name === f.meta.name));
    return result;
  }, [argumentsArea.values, sortingArea.values]);

  const onReportTypeChanged = useCallback(
    (reportType: ReportType) => {
      dispatch(
        currentReportActions.updateReport({
          reportType,
          configuration: { conditions: report?.configuration.conditions } as ChartConfiguration,
        })
      );
      dispatch(currentReportActions.refreshSession());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [report]
  );

  const handleCanDropItem = useCallback(() => canDropValueItem(reportRef.current?.reportType, valuesRef.current), []);

  return (
    <Grid container sx={{ flex: 1, flexDirection: "column", gap: "1rem", mt: "1rem" }}>
      <Grid container px="1.3rem">
        <Visualization type={report?.reportType || ReportType.PieChart} onSelect={onReportTypeChanged} />

        <Tabs value={selectedTab} sx={{ borderBottom: "none" }} onChange={(_, value) => setSelectedTab(value)}>
          <Tab label="Fields" />
          <Tab label="Settings" />
          <Tab label="Format" />
        </Tabs>
      </Grid>
      <Divider sx={{ mt: -2 }} />
      {selectedTab === 0 && (
        <ScrollableFlexContainer
          scrollContainerSx={{ display: "flex", flexDirection: "column", gap: "1.5rem", px: "1.3rem" }}
        >
          <ConditionsDropFieldsContainer area={conditionsArea} disableEqualitySelection={true} />
          <ArgumentDropFieldsContainer />
          <MeasuresDropFieldsContainer
            conditionsArea={conditionsArea}
            valuesArea={valuesArea}
            fields={argumentsArea.values}
            canDropItem={handleCanDropItem}
          />
          <SortDropsFieldsContainer area={sortingArea} availableFields={availableSortFields} />
        </ScrollableFlexContainer>
      )}
      {selectedTab === 1 && <StyleContainer />}
      {selectedTab === 2 && <FormatContainer />}
    </Grid>
  );
}
