import { ITemplateProps, Template } from "devextreme-react/core/template";
import DataGrid, { Paging, Scrolling, Sorting, Summary, TotalItem } from "devextreme-react/data-grid";
import { CellClickEvent } from "devextreme/ui/data_grid";
import React from "react";
import { AnyObject } from "../../../../../shared/types";
import { PivotDataGrandTotals, PivotRowMeasureCell } from "../../../../api/biApi.types";
import { InternalColumn } from "./PreviewComponent.Types";
import "./ReportGrid.css";

interface Props {
  columns: InternalColumn[];
  grandTotals?: PivotDataGrandTotals;
  templates: ITemplateProps[];
  data: AnyObject[];
  showSummaries?: boolean;
  size: { width: number; height: number };
  onCellClicked: (cell: PivotRowMeasureCell | undefined) => void;
  onGridRef?: (ref: DataGrid | null) => void;
}

export default class ReportGrid extends React.Component<Props> {
  override shouldComponentUpdate(nextProps: Readonly<Props>) {
    const result =
      this.props.data !== nextProps.data ||
      this.props.columns !== nextProps.columns ||
      this.props.templates !== nextProps.templates ||
      this.props.showSummaries !== nextProps.showSummaries ||
      this.props.size.height !== nextProps.size.height ||
      this.props.size.width !== nextProps.size.width;

    return result;
  }

  calculateGrandTotalRow(options: { name: string; totalValue: number | string; summaryProcess: string }) {
    if (options.name === "lines") {
      options.totalValue = "Grand Total";
    } else {
      if (options.summaryProcess === "start") {
        const grandTotal = this.props.grandTotals?.[options.name];
        if (grandTotal !== undefined) {
          options.totalValue = grandTotal.formattedValue;
        }
      }
    }
  }

  setRef(ref: DataGrid<unknown, unknown> | null) {
    this.props.onGridRef && this.props.onGridRef(ref);
  }

  onCellClick(e: CellClickEvent<unknown, unknown>) {
    if (e.rowIndex < 0 || !e.columnIndex || e.rowType !== "data") {
      return;
    }
    this.props.onCellClicked(e.value as PivotRowMeasureCell);
  }

  override render(): React.ReactNode {
    return (
      <DataGrid
        ref={this.setRef.bind(this)}
        className="preview-grid"
        dataSource={this.props.data}
        columns={this.props.columns}
        columnResizingMode={"widget"}
        allowColumnResizing
        showBorders
        showColumnLines
        showRowLines
        columnAutoWidth
        hoverStateEnabled={true}
        height={this.props.size.height}
        width={this.props.size.width}
        onCellClick={this.onCellClick.bind(this)}
      >
        <Scrolling mode={"standard"} columnRenderingMode="standard"></Scrolling>
        {this.props.templates.map((props) => (
          <Template key={props.name} {...props}></Template>
        ))}
        <Paging enabled={false}></Paging>
        <Sorting mode={"none"}></Sorting>
        {this.props.showSummaries && (
          <Summary
            calculateCustomSummary={(options: { name: string; totalValue: number | string; summaryProcess: string }) =>
              this.calculateGrandTotalRow(options)
            }
          >
            <TotalItem key={"lines"} name={"lines"} showInColumn={"lines"} summaryType="custom" />
            {Object.keys(this.props.grandTotals || {}).map((gt) => (
              <TotalItem key={gt} name={gt} showInColumn={gt} summaryType="custom" />
            ))}
          </Summary>
        )}
      </DataGrid>
    );
  }
}
