import objectHash from "object-hash";
import React from "react";
import { useSelector } from "react-redux";
import { selectRefreshToken } from "../../../../../store/currentReportSlice";
import { selectDimensions } from "../../../../../store/metaDataSlice";
import { DelayObservable } from "../../../../../utilities/Observers";
import { GeneralField } from "../../../Types";
import getTrackedMeasureProperties from "../../../utils/getTrackedMeasureProperties";
import { isPivotConfigurationValid } from "../../../utils/isConfigurationValid";
import { BuilderContext } from "../../types";
import { InternalColumn } from "../PreviewComponent.Types";
import { getAllFilters } from "../utilities/getAllConditions";
import toGridColumn from "../utilities/toGridColumn";
import useDataLoadingBuilder from "./useDataLoadingBuilder";

export type PreviewGridStateType = ReturnType<typeof useGridStateBuilder>;

export default function useGridStateBuilder() {
  const dimensions = useSelector(selectDimensions);
  const [columns, setColumns] = React.useState<InternalColumn[]>([]);

  const { conditionsArea, rowsArea, columnsArea, valuesArea, sortArea, settingsArea } =
    React.useContext(BuilderContext);
  const { calculate, error, calculating, pivot, failedMeasures, handleInvalidConfiguration } = useDataLoadingBuilder();
  const refreshToken = useSelector(selectRefreshToken);

  const measuresHash = React.useMemo(
    () => objectHash(getTrackedMeasureProperties(valuesArea.values)),
    [valuesArea.values]
  );

  const conditionsFiltersHash = React.useMemo(
    () => objectHash(getAllFilters(conditionsArea.values, valuesArea.values)),
    [conditionsArea.values, valuesArea.values]
  );

  const rowsAreaHash = React.useMemo(() => rowFieldHash(rowsArea.values), [rowsArea.values]);
  const columnsAreaHash = React.useMemo(() => generalFieldHash(columnsArea.values), [columnsArea.values]);
  const configurationValid = React.useMemo(() => {
    const fields = rowsArea.values.concat(columnsArea.values).concat(rowsArea.values);
    return isPivotConfigurationValid(conditionsArea.values, fields, valuesArea.values, dimensions);
  }, [columnsArea.values, conditionsArea.values, dimensions, rowsArea.values, valuesArea.values]);

  React.useLayoutEffect(() => {
    const subscription = DelayObservable.subscribe(calculate);
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (configurationValid.valid) {
      DelayObservable.next(refreshToken);
    } else {
      handleInvalidConfiguration();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    conditionsFiltersHash,
    rowsAreaHash,
    columnsAreaHash,
    measuresHash,
    refreshToken,
    settingsArea.settings,
    sortArea.values,
    configurationValid.valid,
  ]);

  React.useEffect(() => {
    if (!pivot) {
      setColumns([]);
    } else {
      const gridColumns = pivot.columns.map(toGridColumn);
      setColumns(gridColumns);
    }
  }, [pivot]);

  return { pivot, columns, error, calculating, configurationValid, failedMeasures };
}

function rowFieldHash(fields: GeneralField[]) {
  return objectHash(
    fields.map((v) => ({
      n: v.meta.name,
      g: v.config.grouping,
      df: v.config.displayValues,
      showTotals: v.config.style?.showTotals,
      suppressEmptyValues: v.config.suppressEmptyValues,
      showLogoIcon: v.config.showLogoIcon,
      customLabel: v.config.customLabel,
    }))
  );
}

export function generalFieldHash(fields: GeneralField[]) {
  return objectHash(
    fields.map((v) => ({
      n: v.meta.name,
      g: v.config.grouping,
      df: v.config.displayValues,
      showTotals: v.config.style?.showTotals,
      suppressEmptyValues: v.config.suppressEmptyValues,
      customLabel: v.config.customLabel,
    }))
  );
}
