import { Switch, Typography } from "@mui/material";
import { MenuItemStyled } from "../../../../../shared/components/MenuItemStyled";
import { GeneralField, ValueField } from "../../Types";

interface DimensionProps {
  field: GeneralField;
  onChange: (value: boolean) => void;
}

export function SuppressNoValueDimensionComponent({ field, onChange }: DimensionProps) {
  return <SuppressNoValueComponent value={field.config.suppressEmptyValues} onChange={onChange} />;
}

interface MeasureProps {
  field: ValueField;
  onChange: (value: boolean) => void;
}
export function SuppressNoValueMeasureComponent({ field, onChange }: MeasureProps) {
  return <SuppressNoValueComponent value={field.config.suppressEmptyValues} onChange={onChange} />;
}

function SuppressNoValueComponent({
  value,
  onChange,
}: Pick<DimensionProps, "onChange"> & { value: boolean | undefined }) {
  return (
    <MenuItemStyled sx={{ display: "flex", flex: 1, flexDirection: "row", gap: 1 }} onClick={() => onChange(!value)}>
      <Typography sx={{ display: "flex", flex: 1 }}>Suppress Empty Values</Typography>
      <Switch checked={value === true} />
    </MenuItemStyled>
  );
}
