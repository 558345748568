import { Grid } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import InlineLoader from "../../../shared/components/inlineLoader/InlineLoader";
import sharedBiClient from "../../../shared/reporting/api/biClient";
import { useEnhancedBiApiClientProvider } from "../../contexts/ApiClientProviderContext";
import { useClientContext } from "../../contexts/ClientContext";
import useApplyTemplate from "../../hooks/useApplyTemplate";
import useDefineReportData from "../../hooks/useDefineReportData";
import { useLocalization } from "../../hooks/useLocalization";
import useMetaData from "../../hooks/useMetaData";
import { selectCurrentReport } from "../../store/currentReportSlice";
import { metaDataActions, selectMetaData } from "../../store/metaDataSlice";
import { setTemplates } from "../../store/reportTemplatesSlice";
import ApplyingDialog from "../common/dialogs/ApplyingDialog";
import TemplateNotFound from "../template/TemplateNotFound";
import ErrorApplyingTemplateDialog from "../template/dialogs/ErrorApplyingTemplateDialog";
import EditReportPage from "./EditReportPage";
import ReportNotFoundOrAccessForbidden from "./ReportNotFoundOrAccessForbidden";
import DrillDownEntry from "./common/drillDown/DrillDownEntry";
import { getOrganizationReportTemplatesUrl } from "./common/utilities/editReportUrl";

const EditRoot = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { metadata: locale, report_templates: templatesLocale, report: reportLocale } = useLocalization();
  const { clientCode, clientInfo } = useClientContext();
  const { loadMetaData } = useMetaData({ includeDictionaries: false });
  const metaData = useSelector(selectMetaData);
  const report = useSelector(selectCurrentReport);
  const { showReportNotFound, showSwitchingReport } = useDefineReportData();
  const { getReportsUrl } = useEnhancedBiApiClientProvider();

  const {
    loadingTemplate,
    applyingTemplateName,
    showTemplateFailedToApply,
    showTemplateNotFound,
    handleCloseFailedToApplyDialog,
    handleTryAgain,
  } = useApplyTemplate();

  React.useEffect(() => {
    if (!metaData.loaded && report !== undefined) {
      loadMetaData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaData.loaded, report]);

  React.useEffect(() => {
    if (report?.reportType !== undefined) {
      dispatch(metaDataActions.updateReportType(report.reportType));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report?.reportType]);

  React.useEffect(() => {
    if (!clientInfo?.organization) return;
    sharedBiClient.getCompanyReportTemplates().then((resp) => {
      if (resp.success && resp.data !== undefined) {
        dispatch(setTemplates(resp.data));
      }
    });
  }, [clientInfo?.organization, dispatch]);

  if (id === undefined) {
    return <Navigate to={""} />;
  }

  if (showReportNotFound === true) {
    return <ReportNotFoundOrAccessForbidden getReportsUrl={getReportsUrl} />;
  }

  if (showTemplateNotFound === true) {
    return <TemplateNotFound backUrl={getOrganizationReportTemplatesUrl(clientCode)} />;
  }

  if (!metaData.loaded || report === undefined) {
    return <InlineLoader text={locale.loading_label} />;
  }

  return (
    <Grid container sx={{ bgcolor: "white" }}>
      <EditReportPage />
      <DrillDownEntry />
      {loadingTemplate && <ApplyingDialog open text={templatesLocale.applying_template} />}
      {showSwitchingReport && <ApplyingDialog open text={reportLocale.loading_report} />}
      {showTemplateFailedToApply && (
        <ErrorApplyingTemplateDialog
          open
          templateName={applyingTemplateName || "The"}
          onClose={handleCloseFailedToApplyDialog}
          onTryAgain={handleTryAgain}
        />
      )}
    </Grid>
  );
};

export default EditRoot;
