import { Badge, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { ReportConfiguration, ReportType } from "../../../shared/reporting/api/biClient.types";
import { useLocalization } from "../../hooks/useLocalization";
import AreaChartIcon from "../../../shared/reporting/icons/reportTypes/AreaChartIcon";
import BarChartIcon from "../../../shared/reporting/icons/reportTypes/BarChartIcon";
import DoughnutChartIcon from "../../../shared/reporting/icons/reportTypes/DoughnutChartIcon";
import LineChartIcon from "../../../shared/reporting/icons/reportTypes/LineChartIcon";
import PieChartIcon from "../../../shared/reporting/icons/reportTypes/PieChartIcon";
import PivotIcon from "../../../shared/reporting/icons/reportTypes/PivotIcon";
import TabularIcon from "../../../shared/reporting/icons/reportTypes/TabularIcon";
import { selectCurrentReport, selectIsReportChanged, selectReportConfiguration } from "../../store/currentReportSlice";
import { areAllFieldsEmpty } from "../../utilities/Utilities";
import SaveReportDialog from "../builder/common/saving/SaveReportDialog";
import UnsavedChangesPopover from "./UnsavedChangesPopover";

interface Props {
  type: ReportType;
  onSelect: (newType: ReportType) => void;
}

export default function Visualization({ type, onSelect }: Props) {
  const { common: locale } = useLocalization();
  const isReportChanged = useSelector(selectIsReportChanged);
  const report = useSelector(selectCurrentReport);
  const reportConfiguration = useSelector(selectReportConfiguration);
  const [showUnsavedTooltip, setShowUnsavedTooltip] = React.useState(false);
  const [showSaveDialog, setShowSaveDialog] = React.useState(false);
  const [newType, setNewType] = React.useState<ReportType>();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();
  const [isConfigurationEmpty, setIsConfigurationEmpty] = React.useState<boolean>();

  const onReportTypeClicked = React.useCallback(
    (newType: ReportType, ref: HTMLButtonElement | null) => {
      if (type === newType) return;
      if (isConfigurationEmpty === false && isReportChanged === true && ref !== null) {
        setNewType(newType);
        setAnchorEl(ref);
        setShowUnsavedTooltip(true);
      } else {
        onSelect(newType);
      }
    },
    [type, isReportChanged, onSelect, isConfigurationEmpty]
  );

  const handleSave = React.useCallback(() => {
    setShowSaveDialog(true);
    setShowUnsavedTooltip(false);
    setAnchorEl(undefined);
  }, []);

  const handleContinue = React.useCallback(() => {
    if (newType !== undefined) onSelect(newType);
    setShowUnsavedTooltip(false);
    setAnchorEl(undefined);
  }, [newType, onSelect]);

  React.useEffect(() => {
    const configuration = reportConfiguration as ReportConfiguration;
    if (configuration) {
      const { settings, ...fields } = configuration;
      const isConfigurationEmpty = fields && areAllFieldsEmpty(fields);
      setIsConfigurationEmpty(isConfigurationEmpty);
    }
  }, [reportConfiguration, report]);

  return (
    <Stack gap={2}>
      <Typography variant="subtitle2" color={(theme) => theme.palette.text.primary}>
        {locale.visualizations_label}
      </Typography>
      <Stack flexDirection="row">
        <TabularReport selectedReport={type} isReportChanged={isReportChanged} onSelected={onReportTypeClicked} />
        <PivotReport selectedReport={type} isReportChanged={isReportChanged} onSelected={onReportTypeClicked} />
        <BarReport selectedReport={type} isReportChanged={isReportChanged} onSelected={onReportTypeClicked} />
        <LineReport selectedReport={type} isReportChanged={isReportChanged} onSelected={onReportTypeClicked} />
        <AreaReport selectedReport={type} isReportChanged={isReportChanged} onSelected={onReportTypeClicked} />
        <PieReport selectedReport={type} isReportChanged={isReportChanged} onSelected={onReportTypeClicked} />
        <DoughnutReport selectedReport={type} isReportChanged={isReportChanged} onSelected={onReportTypeClicked} />
      </Stack>
      {showUnsavedTooltip && isConfigurationEmpty === false && (
        <UnsavedChangesPopover
          open={true}
          anchorEl={anchorEl}
          onSave={handleSave}
          onContinue={handleContinue}
          onClose={() => {
            setShowUnsavedTooltip(false);
            setAnchorEl(undefined);
          }}
        />
      )}
      {showSaveDialog && report && (
        <SaveReportDialog
          report={report}
          open={true}
          title="Save Report"
          saveBtnTitle="Save"
          reportConfiguration={reportConfiguration}
          onClose={() => setShowSaveDialog(false)}
        />
      )}
    </Stack>
  );
}

interface ReportIconProps {
  selectedReport: ReportType;
  isReportChanged: boolean;
  onSelected: (newType: ReportType, ref: HTMLButtonElement | null) => void;
}

function TabularReport({ selectedReport, isReportChanged, onSelected }: ReportIconProps) {
  const elementRef = React.createRef<HTMLButtonElement>();
  const selected = selectedReport === ReportType.Tabular;

  return (
    <CustomBadge show={selected && isReportChanged}>
      <Tooltip title={getToolTipText("Tabular Report", selected && isReportChanged)}>
        <IconButton
          ref={elementRef}
          sx={{ borderRadius: "unset", svg: { fill: "none" } }}
          onClick={() => onSelected(ReportType.Tabular, elementRef.current)}
        >
          <TabularIcon
            sx={(theme) => ({ path: { fill: selected ? theme.palette.primary.main : theme.palette.secondary.light } })}
          />
        </IconButton>
      </Tooltip>
    </CustomBadge>
  );
}

function PivotReport({ selectedReport, isReportChanged, onSelected }: ReportIconProps) {
  const elementRef = React.createRef<HTMLButtonElement>();
  const selected = selectedReport === ReportType.Pivot;
  return (
    <CustomBadge show={selected && isReportChanged}>
      <Tooltip title={getToolTipText("Pivot Report", selected && isReportChanged)}>
        <IconButton
          ref={elementRef}
          sx={{ borderRadius: "unset" }}
          onClick={() => onSelected(ReportType.Pivot, elementRef.current)}
        >
          <PivotIcon
            sx={(theme) => ({ color: selected ? theme.palette.primary.main : theme.palette.secondary.light })}
          />
        </IconButton>
      </Tooltip>
    </CustomBadge>
  );
}
function BarReport({ selectedReport, isReportChanged, onSelected }: ReportIconProps) {
  const elementRef = React.createRef<HTMLButtonElement>();
  const selected = selectedReport === ReportType.BarChart;
  return (
    <CustomBadge show={selected && isReportChanged}>
      <Tooltip title={getToolTipText("Bar Chart Report", selected && isReportChanged)}>
        <IconButton
          ref={elementRef}
          sx={{ borderRadius: "unset" }}
          onClick={() => onSelected(ReportType.BarChart, elementRef.current)}
        >
          <BarChartIcon
            sx={(theme) => ({ color: selected ? theme.palette.primary.main : theme.palette.secondary.light })}
          />
        </IconButton>
      </Tooltip>
    </CustomBadge>
  );
}
function LineReport({ selectedReport, isReportChanged, onSelected }: ReportIconProps) {
  const elementRef = React.createRef<HTMLButtonElement>();
  const selected = selectedReport === ReportType.LineChart;
  return (
    <CustomBadge show={selected && isReportChanged}>
      <Tooltip title={getToolTipText("Line Chart Report", selected && isReportChanged)}>
        <IconButton
          ref={elementRef}
          sx={{ borderRadius: "unset" }}
          onClick={() => onSelected(ReportType.LineChart, elementRef.current)}
        >
          <LineChartIcon
            sx={(theme) => ({ color: selected ? theme.palette.primary.main : theme.palette.secondary.light })}
          />
        </IconButton>
      </Tooltip>
    </CustomBadge>
  );
}
function AreaReport({ selectedReport, isReportChanged, onSelected }: ReportIconProps) {
  const elementRef = React.createRef<HTMLButtonElement>();
  const selected = selectedReport === ReportType.AreaChart;
  return (
    <CustomBadge show={selected && isReportChanged}>
      <Tooltip title={getToolTipText("Area Chart Report", selected && isReportChanged)}>
        <IconButton
          ref={elementRef}
          sx={{ borderRadius: "unset" }}
          onClick={() => onSelected(ReportType.AreaChart, elementRef.current)}
        >
          <AreaChartIcon
            sx={(theme) => ({ color: selected ? theme.palette.primary.main : theme.palette.secondary.light })}
          />
        </IconButton>
      </Tooltip>
    </CustomBadge>
  );
}
function PieReport({ selectedReport, isReportChanged, onSelected }: ReportIconProps) {
  const elementRef = React.createRef<HTMLButtonElement>();
  const selected = selectedReport === ReportType.PieChart;
  return (
    <CustomBadge show={selected && isReportChanged}>
      <Tooltip title={getToolTipText("Pie Chart Report", selected && isReportChanged)}>
        <IconButton
          ref={elementRef}
          sx={{ borderRadius: "unset" }}
          onClick={() => onSelected(ReportType.PieChart, elementRef.current)}
        >
          <PieChartIcon
            sx={(theme) => ({ color: selected ? theme.palette.primary.main : theme.palette.secondary.light })}
          />
        </IconButton>
      </Tooltip>
    </CustomBadge>
  );
}

function DoughnutReport({ selectedReport, isReportChanged, onSelected }: ReportIconProps) {
  const elementRef = React.createRef<HTMLButtonElement>();
  const selected = selectedReport === ReportType.DoughnutChart;
  return (
    <CustomBadge show={selected && isReportChanged}>
      <Tooltip title={getToolTipText("Doughnut Chart Report", selected && isReportChanged)}>
        <IconButton
          ref={elementRef}
          sx={{ borderRadius: "unset" }}
          onClick={() => onSelected(ReportType.DoughnutChart, elementRef.current)}
        >
          <DoughnutChartIcon
            sx={(theme) => ({ color: selected ? theme.palette.primary.main : theme.palette.secondary.light })}
          />
        </IconButton>
      </Tooltip>
    </CustomBadge>
  );
}

function CustomBadge({ show, children }: React.PropsWithChildren<{ show: boolean }>) {
  if (show === false) return <>{children}</>;
  return (
    <Badge
      variant="dot"
      color="primary"
      sx={{
        ".BaseBadge-badge": {
          height: "6px",
          minWidth: "6px",
          transform: "unset",
        },
      }}
    >
      {children}
    </Badge>
  );
}

function getToolTipText(text: string, selectedAndChanged: boolean) {
  if (selectedAndChanged === true) {
    return (
      <Stack>
        <Typography variant="caption">{text}</Typography>
        <Typography variant="caption">* has unsaved changes</Typography>
      </Stack>
    );
  }
  return <Typography variant="caption">{text}</Typography>;
}
