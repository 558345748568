import { Box, Stack } from "@mui/material";
import { Report } from "../../../../shared/reporting/api/biClient.types";
import PartContainer from "../PartContainer";
import { useLocalization } from "../../../hooks/useLocalization";
import ChartContainer from "./ChartContainer";
import TableComponent from "./table/TableComponent";
import PreviewComponent from "./PreviewComponent";
import PreviewActions from "../common/PreviewActions";
import useChartStateBuilder from "./hooks/useChartStateBuilder";
import withExport from "./hocs/withExport";
import BuildProgressWrapper from "../BuildProgressWrapper";

interface Props {
  report: Report;
  exportAllowed?: boolean;
}

export default function ChartEditor({ report, exportAllowed = true }: Props) {
  const { common: locale } = useLocalization();

  return (
    <ChartContainer report={report}>
      <Stack flexDirection="row" flex={1}>
        <PartContainer sx={{ maxWidth: "24rem", minWidth: "24rem" }} caption={<Box pl="1.3rem">Chart Builder</Box>}>
          <TableComponent />
        </PartContainer>
        <BuildProgressWrapper>
          <PartContainer
            sx={{ borderRight: "unset", p: "1.3rem" }}
            containerSx={{ position: "relative", gap: "1rem" }}
            caption={locale.report_preview_label}
            actions={<PreviewActions />}
          >
            <PreviewContainer exportAllowed={exportAllowed} />
          </PartContainer>
        </BuildProgressWrapper>
      </Stack>
    </ChartContainer>
  );
}

const PreviewComponentWithExport = withExport(PreviewComponent);

function PreviewContainer({ exportAllowed = true }: { exportAllowed?: boolean }) {
  const state = useChartStateBuilder();

  return exportAllowed ? <PreviewComponentWithExport state={state} /> : <PreviewComponent state={state} />;
}
