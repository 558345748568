import { PivotColumnResponse } from "../../../../../api/biApi.types";
import { InternalColumn } from "../PreviewComponent.Types";

export default function toGridColumn(column: PivotColumnResponse): InternalColumn {
  return {
    dataField: column.id,
    columns: column.columns?.map(toGridColumn) || [],
    formattedValues: column.formattedValues,
    toolTip: column.toolTip,
    headerCellTemplate: "header",
    alignment: column.id === "lines" ? "left" : "right",
    cellTemplate: column.id === "lines" ? "lines" : "measure",
    minWidth: 150,
    fixed: column.id === "lines",
  } as InternalColumn;
}
