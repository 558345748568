import cloneDeep from "../../../../shared/utilities/cloneDeep";
import { defined } from "../../../../shared/utilities/typeHelper";
import {
  ConditionConfiguration,
  ConditionDescriptor,
  DateRangeType,
  PivotValuesRequest,
  TabularFieldType,
} from "../../../../shared/reporting/api/biClient.types";
import { DimensionDescriptor, TabularValueRequest } from "../../../api/biApi.types";
import { toAmountType, toCalculateBy } from "../common/customMeasure/utilities/measureConverter";
import { getValidConditionDescriptors } from "../common/utilities/getValidConditions";
import { ConditionField, GeneralField, ValueField } from "../Types";
import { TableField } from "../tabular/hooks/TableField";

enum DimensionNames {
  PostingDate = "PostingDate",
  TransactionDate = "TransactionDate",
}

export enum InvalidConfigurationReason {
  ConditionsFieldAndCustomConditions = 1,
  FieldAndCustomConditions = 2,
  MultipleFields = 3,
  MultipleCustomConditions = 4,
  NoDateFields = 5,
  MandatoryFieldIsNotSelected = 6,
}

export function isTabularConfigurationValid(
  conditions: ConditionField[],
  fields: TableField[],
  dimensions: DimensionDescriptor[]
): MeasureValidReturnType & { measure?: string } {
  if (fields.length === 0) return { valid: false };
  const validConditions = getValidConditionDescriptors(conditions);
  const dimensionFields = getDimensionFields(fields);
  const measureFields = getMeasureFields(fields, conditions);

  const invalidMeasure = measureFields
    .map((mf) => {
      const valid = isMeasureValid(validConditions, dimensionFields, mf, dimensions);
      return { ...valid, measure: mf.guid };
    })
    .find((result) => result.valid === false);

  if (invalidMeasure !== undefined) {
    return invalidMeasure;
  }
  if (
    !areMandatoryFieldsSet(
      conditions,
      fields.flatMap((f) => f.measure?.config.customConditions || [])
    )
  ) {
    return { valid: false, reason: InvalidConfigurationReason.MandatoryFieldIsNotSelected };
  }
  return { valid: true };
}

export function isPivotConfigurationValid(
  conditions: ConditionField[],
  fields: GeneralField[],
  measures: ValueField[],
  dimensions: DimensionDescriptor[]
): MeasureValidReturnType & { measure?: string } {
  if (fields.length === 0) return { valid: false };
  const validConditions = getValidConditionDescriptors(conditions);
  const measureFields = measures.map((m) => getMeasureField(conditions, m));

  const invalidMeasure = measureFields
    .map((mf) => {
      const valid = isMeasureValid(validConditions, fields, mf, dimensions);
      return { ...valid, measure: mf.guid };
    })
    .find((result) => result.valid === false);

  if (invalidMeasure !== undefined) {
    return invalidMeasure;
  }

  if (
    !areMandatoryFieldsSet(
      conditions,
      measures.flatMap((f) => f.config.customConditions || [])
    )
  ) {
    return { valid: false, reason: InvalidConfigurationReason.MandatoryFieldIsNotSelected };
  }
  return { valid: true };
}

export function isMeasureConfigurationValid(
  conditions: ConditionField[],
  fields: GeneralField[],
  dimensions: DimensionDescriptor[],
  value: ValueField
): MeasureValidReturnType {
  const validConditions = getValidConditionDescriptors(conditions);
  const measureField = getMeasureField(conditions, value);

  const valid = isMeasureValid(validConditions, fields, measureField, dimensions);
  return valid;
}

export function isMeasureValid(
  conditions: ConditionDescriptor[],
  fields: GeneralField[],
  measureField: TabularValueRequest,
  dimensions: DimensionDescriptor[]
): MeasureValidReturnType {
  const conditionDates = getDateConditions(conditions, dimensions);
  const dateFields = getDateFields(fields);
  const customConditionDate = getDateCustomConditions(measureField, dimensions);

  if (dateFields.length > 0) {
    const referenceDateField = getDimensionDescriptor(defined(dateFields[0]).meta.name, dimensions);
    if (customConditionDate.some((ccd) => ccd.dimensionName === referenceDateField.name)) {
      return { valid: false, referenceDateField, reason: InvalidConfigurationReason.FieldAndCustomConditions };
    }
    return { valid: true, referenceDateField };
  }

  if (conditionDates.length > 0) {
    const referenceDateField = getDimensionDescriptor(defined(conditionDates[0]).dimensionName, dimensions);
    return { valid: true, referenceDateField };
  }

  if (customConditionDate.length > 0) {
    const referenceDateField = getDimensionDescriptor(defined(customConditionDate[0]).dimensionName, dimensions);
    if (customConditionDate.length === 1) return { valid: true, referenceDateField };
    return { valid: false, referenceDateField, reason: InvalidConfigurationReason.MultipleCustomConditions };
  }

  if (measureField.dateRangeType === DateRangeType.NetChange) return { valid: true };

  return { valid: false, reason: InvalidConfigurationReason.NoDateFields };
}

function getDateConditions(conditions: ConditionDescriptor[], dimensions: DimensionDescriptor[]) {
  return conditions.filter((c) => {
    const meta = defined(dimensions.find((d) => d.name === c.dimensionName));
    return meta.name === DimensionNames.PostingDate || meta.name === DimensionNames.TransactionDate;
  });
}

function getDateFields(fields: GeneralField[]) {
  return fields.filter(
    (f) => f.meta.name === DimensionNames.PostingDate || f.meta.name === DimensionNames.TransactionDate
  );
}

function getDateCustomConditions(measureField: TabularValueRequest, dimensions: DimensionDescriptor[]) {
  if (!measureField.conditions) return [];
  return measureField.conditions.filter((c) => {
    const meta = defined(dimensions.find((d) => d.name === c.dimensionName));
    return meta.name === DimensionNames.PostingDate || meta.name === DimensionNames.TransactionDate;
  });
}

export function getDimensionFields(fields: TableField[]) {
  return fields
    .filter((field) => field.fieldType === TabularFieldType.Dimension)
    .map((field) => field.dimension)
    .filter((dimension): dimension is GeneralField => !!dimension);
}

function getMeasureFields(fields: TableField[], conditions: ConditionField[]) {
  return fields
    .filter((field) => field.fieldType === TabularFieldType.Measure)
    .map((field) => field.measure)
    .filter((measure): measure is ValueField => !!measure)
    .map((measure) => getMeasureField(conditions, measure));
}

function getDimensionDescriptor(name: string, dimensions: DimensionDescriptor[]) {
  return defined(dimensions.find((d) => d.name === name));
}

function getMeasureField(conditions: ConditionField[], measure: ValueField): TabularValueRequest {
  const measureOptions = getMeasure(conditions, measure);
  return { ...measureOptions, hideAggregation: measure.config.hideAggregation };
}

function getMeasure(conditions: ConditionField[], measure: ValueField): PivotValuesRequest {
  const customConditions = getCustomConditions(measure);
  const linkedConditions = getLinkedConditions(measure, conditions);
  const result = mergeConditions(customConditions, linkedConditions);

  return {
    measureName: measure.meta.name,
    guid: measure.config.guid,
    dateRangeType: measure.config.dateRange,
    standalone: measure.config.standalone,
    conditions: result,
    customLabel: measure.config.customLabel,
    calculateByField: getMeasureCalculateByField(measure),
    amountType: getMeasureAmountTypeField(measure),
  };
}

export function getMeasureCalculateByField(measure: ValueField) {
  return measure.config.calculateByField || toCalculateBy(measure.meta.units);
}

export function getMeasureAmountTypeField(measure: ValueField) {
  return measure.config.amountType || toAmountType(measure.meta.units);
}

function getCustomConditions(value: ValueField) {
  if (!value.config.customConditions) return [];
  return value.config.customConditions
    .filter((cc) => cc.filter.values.length > 0)
    .map((cc): ConditionDescriptor => cc.filter);
}

function getLinkedConditions(value: ValueField, conditions: ConditionField[]) {
  if (!value.config.linkedConditions) return [];
  return value.config.linkedConditions
    .map((lc) => conditions.find((c) => c.config.guid === lc)?.config.filter)
    .filter(
      (filter): filter is ConditionDescriptor =>
        (filter && filter && filter.values && filter.values.length > 0) || false
    )
    .map((c) => cloneDeep(c));
}

function mergeConditions(custom: ConditionDescriptor[], linked: ConditionDescriptor[]) {
  const allConditions = [...custom, ...linked];
  return allConditions;
}

function filterUnsetMandatoryField(config: ConditionConfiguration) {
  return config.mandatory && config.filter.values.length === 0;
}

function areMandatoryFieldsSet(conditions: ConditionField[], customConditions: ConditionConfiguration[]) {
  return (
    conditions.filter((c) => filterUnsetMandatoryField(c.config)).length === 0 &&
    customConditions.filter(filterUnsetMandatoryField).length === 0
  );
}

export type MeasureValidReturnType = {
  valid: boolean;
  referenceDateField?: DimensionDescriptor;
  reason?: InvalidConfigurationReason;
};

export type ConfigurationValidReturnType = ReturnType<typeof isTabularConfigurationValid>;
