import { Box, Divider, Grid, Tab, Tabs } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { ReportType, TabularFieldType, TabularConfiguration } from "../../../../../shared/reporting/api/biClient.types";
import Visualization from "../../../common/Visualization";
import ConditionsDropFieldsContainer from "../../pivot/table/conditions/ConditionsDropFieldsContainer";
import { useFieldsStateContext } from "../contexts/FieldsStateContext";
import FieldsDropContainer from "./FieldsDropContainer";
import FormatContainer from "./FormatContainer";
import SortDropsFieldsContainer from "./SortDropsFieldsContainer";
import { useDispatch, useSelector } from "react-redux";
import { currentReportActions, selectCurrentReport } from "../../../../store/currentReportSlice";
import SettingsContainer from "../settings/SettingsContainer";
import GroupsDropFieldsContainer from "./GroupsDropFieldsContainer";
import TableDragLayer from "./TableDragLayer";
import ScrollableFlexContainer from "../../../../../shared/components/ScrollableFlexContainer";
import { ItemDataType } from "../../../../api/biApi.types";
import { referenceDateFields } from "../utilities/referenceDateFields";
import { getAvailableSortFields } from "../../common/utilities/sortFieldStateHelper";

export const TableComponent = () => {
  const dispatch = useDispatch();
  const report = useSelector(selectCurrentReport);
  const { conditionsArea, sortingArea, fieldsArea, groupingArea } = useFieldsStateContext();

  const [selectedTab, setSelectedTab] = useState(0);
  const availableSortFields = useMemo(
    () => getAvailableSortFields(fieldsArea.values, sortingArea.values),
    [fieldsArea.values, sortingArea.values]
  );

  const availableGroupFields = useMemo(() => {
    const result = fieldsArea.values
      .filter(
        (f) =>
          f.fieldType === TabularFieldType.Dimension &&
          f.dimension !== undefined &&
          (f.dimension.meta.type !== ItemDataType.Date || referenceDateFields.includes(f.dimension.meta.name))
      )
      .filter((f) => groupingArea.values.find((v) => v.name === f.guid) === undefined)
      .map((f) => f);
    return result;
  }, [fieldsArea.values, groupingArea.values]);

  const onReportTypeChanged = useCallback(
    (reportType: ReportType) => {
      dispatch(
        currentReportActions.updateReport({
          reportType,
          configuration: { conditions: report?.configuration.conditions } as TabularConfiguration,
        })
      );
      dispatch(currentReportActions.refreshSession());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [report]
  );

  return (
    <Box sx={{ display: "flex", flex: 1, flexDirection: "column", gap: "1rem", mt: "1rem" }}>
      <Grid container px="1.3rem">
        <Visualization type={ReportType.Tabular} onSelect={onReportTypeChanged} />

        <Tabs value={selectedTab} sx={{ borderBottom: "none" }} onChange={(_, value) => setSelectedTab(value)}>
          <Tab label="Fields" />
          <Tab label="Settings" />
          <Tab label="Format" />
        </Tabs>
      </Grid>
      <Divider sx={{ mt: -2 }} />
      {selectedTab === 0 && (
        <ScrollableFlexContainer
          scrollContainerSx={{ display: "flex", flexDirection: "column", gap: "1.5rem", px: "1.3rem" }}
        >
          <ConditionsDropFieldsContainer area={conditionsArea} disableEqualitySelection={true} />
          <FieldsDropContainer fieldsArea={fieldsArea} conditionsArea={conditionsArea} />
          <SortDropsFieldsContainer area={sortingArea} availableFields={availableSortFields} />
          <GroupsDropFieldsContainer area={groupingArea} availableFields={availableGroupFields} />
          <TableDragLayer />
        </ScrollableFlexContainer>
      )}
      {selectedTab === 1 && <SettingsContainer />}
      {selectedTab === 2 && <FormatContainer />}
    </Box>
  );
};

export default TableComponent;
