import cloneDeep from "../../../../../shared/utilities/cloneDeep";
import {
  ChartConfiguration,
  ChartStyleSettings,
  PivotFieldConfiguration,
} from "../../../../../shared/reporting/api/biClient.types";
import { DimensionDescriptor } from "../../../../api/biApi.types";
import { valueToConfiguration } from "../../common/utilities/valueToConfiguration";
import { AreaItemType, ConditionField, GeneralField, SortField, ValueField } from "../../Types";

export const createReportConfiguration = (
  conditions: ConditionField[],
  args: GeneralField[],
  values: ValueField[],
  sorts: SortField[],
  settings: ChartStyleSettings
) => {
  const reportConfiguration: ChartConfiguration = {
    conditions: conditions.map((c) => c.config),
    arguments: args.map(
      (field): PivotFieldConfiguration => ({
        name: field.meta.name,
        grouping: field.config.grouping,
        style: field.config.style,
        displayValues: field.config.displayValues,
        suppressEmptyValues: field.config.suppressEmptyValues,
        customLabel: field.config.customLabel,
      })
    ),
    values: values.map(valueToConfiguration),
    sort: sorts.map((s) => s.config),
    settings: cloneDeep(settings),
  };
  return reportConfiguration;
};

export const configurationToArguments = (configArgs: PivotFieldConfiguration[], dimensions: DimensionDescriptor[]) => {
  try {
    const args = configArgs
      .map((config) => {
        const dimension = dimensions.find((d) => d.name === config.name);
        if (dimension) {
          const copied: GeneralField = { config, meta: dimension, areaItemType: AreaItemType.ARGUMENTS };
          return copied;
        } else {
          // eslint-disable-next-line no-console
          console.log(`Field ${config.name} is not found in the dimensions' list`);
        }
        return undefined;
      })
      .filter((c): c is GeneralField => !!c);
    return args;
  } catch {
    return [];
  }
};
