import { ValueField } from "../../Types";

export function isValueFieldHasParameters(field: ValueField | undefined): boolean {
  const result =
    field?.config.useCustomConditions === true &&
    field.meta.capabilities.customConditionsAllowed &&
    field.config.customConditions &&
    field.config.customConditions.some((cc) => cc.parameter === true);

  return result === undefined ? false : result;
}
