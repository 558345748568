import React from "react";
import {
  Report,
  TabularFieldType,
  TabularConfiguration,
  ReportConfiguration,
} from "../../../../shared/reporting/api/biClient.types";
import { DimensionDescriptor, MeasureDescriptor } from "../../../api/biApi.types";
import { configurationToConditions, configurationToSorts } from "../pivot/utilities/Configurations";
import { FieldsStateContextProvider, FieldsStateContextType } from "./contexts/FieldsStateContext";
import useFieldsState, { FieldsStateReturnType } from "./hooks/useFieldsState";
import { TableField } from "./hooks/TableField";
import {
  configurationToFields,
  configurationToGrouping,
  configurationToSettings,
  createReportConfiguration,
} from "./utilities/configurations";
import { useDispatch, useSelector } from "react-redux";
import { currentReportActions, selectReportConfiguration } from "../../../store/currentReportSlice";
import useCrossFiltering from "../common/hooks/useCrossFiltering";
import useDeferredDictionaryLoading from "../common/hooks/useDeferredDictionaryLoading";
import { selectDimensions, selectMeasures } from "../../../store/metaDataSlice";
import { updateMeasureFields } from "../utils/MeasureUtils";
import { addMissingGroupSorts, setGroupingFlag } from "../common/utilities/sortFieldStateHelper";

interface Props {
  report: Report;
}
export default function TabularContainer({ report, children }: React.PropsWithChildren<Props>) {
  const dispatch = useDispatch();
  const dimensions = useSelector(selectDimensions);
  const measures = useSelector(selectMeasures);
  const reportConfiguration = useSelector(selectReportConfiguration);
  const fieldsState = useFieldsState();

  const fieldsStateRef = React.useRef(fieldsState);

  React.useEffect(() => {
    fieldsStateRef.current = fieldsState;
  }, [fieldsState]);

  React.useEffect(
    () => {
      setReportConfiguration(report, fieldsState, dimensions, measures, reportConfiguration);
      dispatch(currentReportActions.refreshSession());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [report.reportId]
  );

  React.useEffect(() => {
    const configuration = createReportConfiguration(
      fieldsState.conditions,
      fieldsState.fields,
      fieldsState.sorts,
      fieldsState.grouping,
      fieldsState.settings
    );
    dispatch(currentReportActions.updateReportConfiguration(configuration));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsState.conditions, fieldsState.fields, fieldsState.sorts, fieldsState.grouping, fieldsState.settings]);

  React.useEffect(
    () => updateMeasureFields(fieldsStateRef.current, measures),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [measures]
  );

  const context = React.useMemo(() => buildContext(fieldsState), [fieldsState]);

  useCrossFiltering(context.conditionsArea);
  useDeferredDictionaryLoading(context.conditionsArea);

  return <FieldsStateContextProvider {...context}>{children}</FieldsStateContextProvider>;
}

function setReportConfiguration(
  report: Report,
  fieldsState: FieldsStateReturnType,
  dimensions: DimensionDescriptor[],
  measures: MeasureDescriptor[],
  currentReportConfiguration: ReportConfiguration | undefined
) {
  const configuration = report.configuration as TabularConfiguration;
  if (!configuration) {
    return;
  }
  const conditions = currentReportConfiguration?.conditions || configuration.conditions;
  if (conditions) {
    const result = configurationToConditions(conditions, dimensions);
    fieldsState.setConditions(result);
  }
  if (configuration.fields) {
    const result = configurationToFields(configuration.fields, dimensions, measures);
    fieldsState.setFields(result);
  }
  if (configuration.sort) {
    const sorts = addMissingGroupSorts(
      configuration.fields,
      configuration.grouping,
      setGroupingFlag(configuration.fields, configuration.grouping, configuration.sort)
    );
    const result = configurationToSorts(sorts, dimensions);
    const groupMetaNames = configuration.grouping.map(
      (g) => configuration.fields.find((f) => f.guid === g.name)?.dimension?.name ?? ""
    );
    fieldsState.setSorting(result, groupMetaNames);
  }
  if (configuration.grouping) {
    const result = configurationToGrouping(configuration.grouping);
    fieldsState.setGrouping(result);
  }
  if (configuration.settings) {
    const result = configurationToSettings(configuration.settings);
    fieldsState.setSettings(result);
  }
}

function removeField(field: TableField, fieldsState: FieldsStateReturnType) {
  if (field.fieldType === TabularFieldType.Dimension && field.dimension !== undefined) {
    fieldsState.removeSortingByMeta(field.dimension.meta);
  }
  fieldsState.removeField(field);
}

function buildContext(fieldsState: FieldsStateReturnType): FieldsStateContextType {
  const context: FieldsStateContextType = {
    conditionsArea: {
      values: fieldsState.conditions,
      addItem: fieldsState.addCondition,
      removeItem: fieldsState.removeCondition,
      moveItem: fieldsState.moveCondition,
      updateItem: fieldsState.updateCondition,
      updateItemConfig: fieldsState.updateConditionConfig,
    },
    fieldsArea: {
      values: fieldsState.fields,
      addItem: fieldsState.addField,
      removeItem: (item) => removeField(item, fieldsState),
      moveItem: fieldsState.moveField,
      updateDimensionField: fieldsState.updateDimensionField,
      updateDimensionFieldConfig: fieldsState.updateDimensionFieldConfig,
      updateMeasureField: fieldsState.updateMeasureField,
      updateMeasureFieldConfig: fieldsState.updateMeasureFieldConfig,
      updateFieldsOrder: fieldsState.updateFieldsOrder,
    },
    sortingArea: {
      values: fieldsState.sorts,
      addItem: fieldsState.addSorting,
      removeItem: fieldsState.removeSorting,
      moveItem: fieldsState.moveSorting,
      updateItem: fieldsState.updateSorting,
      updateItemConfig: fieldsState.updateSortingConfig,
      setSorting: fieldsState.setSorting,
    },
    groupingArea: {
      values: fieldsState.grouping,
      setGrouping: fieldsState.setGrouping,
      updateGroup: fieldsState.updateGroup,
      addItem: fieldsState.addGroup,
      removeItem: fieldsState.removeGroup,
      moveItem: fieldsState.moveGroup,
    },
    settingsArea: {
      settings: fieldsState.settings,
      update: fieldsState.updateSettings,
    },
  };
  return context;
}
