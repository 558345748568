import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import CustomDragLayer from "./common/dragLayer/CustomDragLayer";
import { ReportAccessType, ReportType } from "../../../shared/reporting/api/biClient.types";
import { Box, Divider, Stack, Typography } from "@mui/material";
import ReportActionHeader from "./ReportActionHeader";
import ExportAction from "./common/saving/ExportAction";
import SaveButton from "./common/saving/SaveButton";
import MoreAction from "./common/saving/MoreAction";
import CollapseContainer from "../common/CollapseContainer";
import PartContainer from "./PartContainer";
import FieldsComponent from "./common/fields/Fields";
import React from "react";
import PivotEditor from "./pivot/PivotEditor";
import TabularEditor from "./tabular/TabularEditor";
import { useExportState } from "./common/hooks/exportState";
import { ExportStateContextProvider } from "./common/contexts/ExportStateContext";
import { useDispatch, useSelector } from "react-redux";
import { currentReportActions, selectCurrentReport, selectResetId } from "../../store/currentReportSlice";
import { useClientPermissionsContext } from "../../../shared/contexts/ClientPermissionsContext";
import { Navigate } from "react-router-dom";
import ChartEditor from "./chart/ChartEditor";
import { isChartReport } from "./tabular/utilities/isChartReport";
import { selectTemplates } from "../../store/reportTemplatesSlice";
import ReportTemplates from "./common/ReportTemplates";
import { useClientContext } from "../../contexts/ClientContext";
import EntityLevelAccess from "../common/EntityLevelAccess";
import { DeveloperPanelWrapper } from "./dev/DeveloperPanelWrapper";
import ShareReportButton from "./common/sharing/ShareReportButton";
import { useGroupsContext } from "../../../shared/reporting/components/groups/contexts/GroupsContext";
import ReportSelector from "../common/report-selector/ReportSelector";
import SaveReportDialog from "./common/saving/SaveReportDialog";
import SaveAsDialog from "./common/saving/SaveAsDialog";
import ReportingIcon from "../../icons/ReportingIcon";

export default function EditReportPage() {
  const dispatch = useDispatch();
  const { clientCode } = useClientContext();
  const { groups } = useGroupsContext();
  const { hasAnyPermission } = useClientPermissionsContext();
  const report = useSelector(selectCurrentReport);
  const templates = useSelector(selectTemplates);
  const exportState = useExportState();
  const resetId = useSelector(selectResetId);

  const chartReport = React.useMemo(() => isChartReport(report), [report]);

  React.useEffect(() => {
    dispatch(currentReportActions.updateReportMode(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (report === undefined) return <></>;

  if (!hasAnyPermission(["ManageReports"])) {
    return <Navigate to={".."} />;
  }

  return (
    <ExportStateContextProvider {...exportState}>
      <DndProvider backend={HTML5Backend}>
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <ReportActionHeader
            report={report}
            ReportSelector={<ReportSelector requiredAccess={ReportAccessType.Write} groups={groups} isGrouped={true} />}
            SaveReportDialogType={SaveReportDialog}
            ProductIcon={ReportingIcon}
          >
            <EntityLevelAccess />
            <Stack direction={"row"} gap={1}>
              {templates.length > 0 && <ReportTemplates templates={templates} clientCode={clientCode} />}
              <ShareReportButton />
              <ExportAction />
              <SaveButton report={report} SaveReportDialogType={SaveReportDialog} SaveAsDialogType={SaveAsDialog} />
              {report.reportId !== undefined && <MoreAction report={report} />}
            </Stack>
          </ReportActionHeader>
          <Divider />
          <Box sx={{ display: "flex", flex: 1 }}>
            <CollapseContainer
              collapseElement={
                <Typography
                  variant="h6"
                  sx={(theme) => ({ color: theme.palette.text.primary, transform: "rotate(90deg)" })}
                >
                  Fields
                </Typography>
              }
            >
              <PartContainer sx={{ maxWidth: "21rem", minWidth: "21rem" }} caption={<Box pl="1.3rem">Fields</Box>}>
                <FieldsComponent />
              </PartContainer>
            </CollapseContainer>
            <React.Fragment key={resetId}>
              {(!report.reportType || report.reportType === ReportType.Pivot) && <PivotEditor report={report} />}
              {report.reportType === ReportType.Tabular && <TabularEditor report={report} />}
              {chartReport && <ChartEditor report={report} />}
            </React.Fragment>
            <DeveloperPanelWrapper />
          </Box>
        </Box>
        <CustomDragLayer />
      </DndProvider>
    </ExportStateContextProvider>
  );
}
