import { Box, Stack } from "@mui/material";
import PartContainer from "../PartContainer";
import PreviewComponent from "./preview/PreviewComponent";
import TableComponent from "./table/TableComponent";
import TabularContainer from "./TabularContainer";
import { useLocalization } from "../../../hooks/useLocalization";
import PreviewActions from "../common/PreviewActions";
import { Report } from "../../../../shared/reporting/api/biClient.types";
import useGridStateBuilder from "./hooks/useGridStateBuilder";
import withExport from "./preview/hocs/withExport";
import BuildProgressWrapper from "../BuildProgressWrapper";
interface Props {
  report: Report;
  exportAllowed?: boolean;
}
export default function TabularEditor({ report, exportAllowed = true }: Props) {
  const { common: locale } = useLocalization();

  return (
    <TabularContainer report={report}>
      <Stack flexDirection="row" flex={1}>
        <PartContainer sx={{ maxWidth: "24rem", minWidth: "24rem" }} caption={<Box pl="1.3rem">Builder</Box>}>
          <TableComponent />
        </PartContainer>
        <BuildProgressWrapper>
          <PartContainer
            sx={{ borderRight: "unset", p: "1.3rem", pt: 2 }}
            containerSx={{ position: "relative", gap: "1rem" }}
            caption={locale.report_preview_label}
            actions={<PreviewActions />}
          >
            <TabularEditorContainer exportAllowed={exportAllowed} />
          </PartContainer>
        </BuildProgressWrapper>
      </Stack>
    </TabularContainer>
  );
}
const PreviewComponentWithExport = withExport(PreviewComponent);

function TabularEditorContainer({ exportAllowed = true }: { exportAllowed?: boolean }) {
  const state = useGridStateBuilder();
  return exportAllowed ? <PreviewComponentWithExport state={state} /> : <PreviewComponent state={state} />;
}
