import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GeneralSettings } from "../types";

export type SettingsState = {
  settings: GeneralSettings;
};

const initialSettingsState: SettingsState = { settings: {} };

const fieldsStateSettingsSlice = createSlice({
  name: "fieldsStateSettings",
  initialState: initialSettingsState,
  reducers: {
    updateSettingsAction: (state, action: PayloadAction<Partial<GeneralSettings>>) => {
      state.settings = { ...state.settings, ...action.payload };
    },
    setSettingsAction: (state, action: PayloadAction<GeneralSettings>) => {
      state.settings = action.payload;
    },
  },
});

export const { updateSettingsAction, setSettingsAction } = fieldsStateSettingsSlice.actions;

export default fieldsStateSettingsSlice;
