import { Box, Divider, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { DndProvider } from "react-dnd";
import { DeveloperPanelWrapper } from "../dev/DeveloperPanelWrapper";
import CustomDragLayer from "../common/dragLayer/CustomDragLayer";
import { HTML5Backend } from "react-dnd-html5-backend";
import CollapseContainer from "../../common/CollapseContainer";
import PartContainer from "../PartContainer";
import FieldsComponent from "../common/fields/Fields";
import { useDispatch, useSelector } from "react-redux";
import { currentReportActions, selectResetId } from "../../../store/currentReportSlice";
import { useClientPermissionsContext } from "../../../../shared/contexts/ClientPermissionsContext";
import { Report, ReportAccessType, ReportType } from "../../../../shared/reporting/api/biClient.types";
import { Navigate } from "react-router-dom";
import ReportActionHeader from "../ReportActionHeader";
import EntityLevelAccess from "../../common/EntityLevelAccess";
import ShareReportButton from "../common/sharing/ShareReportButton";
import SaveButton from "../common/saving/SaveButton";
import MoreAction from "../common/saving/MoreAction";
import ReportSelector from "../../common/report-selector/ReportSelector";
import ReportEditor from "./editor/ReportEditor";
import TabularContainer from "../tabular/TabularContainer";
import SaveReportDialog from "./SaveReportDialog";
import SaveAsDialog from "./SaveAsDialog";
import AnalyticsApiIcon from "../../../icons/AnalyticsApiIcon";

interface Props {
  report: Report;
}
const EditReportPage = ({ report }: Props) => {
  const dispatch = useDispatch();

  const { hasAnyPermission } = useClientPermissionsContext();
  const resetId = useSelector(selectResetId);

  useEffect(() => {
    dispatch(currentReportActions.updateReportMode(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (report === undefined) return <></>;

  if (!hasAnyPermission(["ManageReports"])) {
    return <Navigate to={".."} />;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Stack height={"100%"}>
        <ReportActionHeader
          report={report}
          ReportSelector={
            <ReportSelector requiredAccess={ReportAccessType.Write} groups={undefined} isGrouped={false} />
          }
          SaveReportDialogType={SaveReportDialog}
          ProductIcon={AnalyticsApiIcon}
        >
          <EntityLevelAccess />
          <Stack direction={"row"} gap={1}>
            <ShareReportButton />
            <SaveButton report={report} SaveReportDialogType={SaveReportDialog} SaveAsDialogType={SaveAsDialog} />
            {report.reportId !== undefined && <MoreAction report={report} hideChangeGroup />}
          </Stack>
        </ReportActionHeader>
        <Divider />
        <Box sx={{ display: "flex", overflowY: "auto", height: "100%" }}>
          <CollapseContainer
            collapseElement={
              <Typography
                variant="h6"
                sx={(theme) => ({ color: theme.palette.text.primary, transform: "rotate(90deg)" })}
              >
                Fields
              </Typography>
            }
          >
            <PartContainer sx={{ maxWidth: "21rem", minWidth: "21rem" }} caption={<Box pl="1.3rem">Fields</Box>}>
              <FieldsComponent />
            </PartContainer>
          </CollapseContainer>
          <React.Fragment key={resetId}>
            {report.reportType === ReportType.Tabular && (
              <TabularContainer report={report}>
                <ReportEditor />
              </TabularContainer>
            )}
          </React.Fragment>
          <DeveloperPanelWrapper />
        </Box>
      </Stack>
      <CustomDragLayer />
    </DndProvider>
  );
};

export default EditReportPage;
