/* eslint-disable no-console */
import {
  DateRangeType,
  GroupingField,
  TabularConfiguration,
  TabularFieldConfiguration,
  TabularFieldType,
  TabularGeneralSettings,
} from "../../../../../shared/reporting/api/biClient.types";
import cloneDeep from "../../../../../shared/utilities/cloneDeep";
import { generateGuid } from "../../../../../shared/utilities/generateGuid";
import { DimensionDescriptor, MeasureDescriptor } from "../../../../api/biApi.types";
import { AreaItemType, ConditionField, SortField } from "../../Types";
import { TableField } from "../hooks/TableField";

export const createReportConfiguration = (
  conditions: ConditionField[],
  fields: TableField[],
  sorts: SortField[],
  grouping: GroupingField[],
  settings: TabularGeneralSettings
) => {
  const reportConfiguration: TabularConfiguration = {
    conditions: conditions.map((c) => c.config),
    fields: fields.map((field) => {
      const fieldConfig: TabularFieldConfiguration = {
        guid: field.guid,
        fieldType: field.fieldType,
        dimension: field.dimension
          ? {
              name: field.dimension?.meta.name,
              format: field.dimension?.config.format,
              grouping: field.dimension?.config.grouping,
              customLabel: field.dimension?.config.customLabel,
              suppressEmptyValues: field.dimension?.config.suppressEmptyValues,
            }
          : undefined,
        measure: field.measure?.config,
        showLogoIcon: field.dimension?.config.showLogoIcon,
      };
      return fieldConfig;
    }),
    sort: sorts.map((s) => ({ name: s.config.name, ordering: s.config.ordering, caption: s.config.caption })),
    grouping: [...grouping],
    settings: { ...settings },
  };
  return reportConfiguration;
};

export const configurationToFields = (
  fields: TabularFieldConfiguration[],
  dimensions: DimensionDescriptor[],
  measures: MeasureDescriptor[]
) => {
  try {
    const tableField = fields
      .map((field) => {
        const tableField: TableField = { guid: field.guid, fieldType: field.fieldType };
        if (field.fieldType === TabularFieldType.Dimension) {
          const dimension = dimensions.find((d) => d.name === field.dimension?.name);
          if (dimension) {
            tableField.dimension = {
              meta: dimension,
              config: {
                name: dimension.name,
                displayValues: dimension.displayValues,
                showLogoIcon: field.showLogoIcon,
                format: field.dimension?.format,
                grouping: field.dimension?.grouping,
                customLabel: field.dimension?.customLabel,
                suppressEmptyValues: field.dimension?.suppressEmptyValues,
              },
              areaItemType: AreaItemType.FIELDS,
            };
          } else {
            console.log(`Field ${field.dimension} is not found in the dimensions' list`);
            return undefined;
          }
        } else if (field.fieldType === TabularFieldType.Measure) {
          const measure = measures.find((m) => m.name === field.measure?.name);
          if (measure && field.measure !== undefined) {
            tableField.measure = {
              meta: measure,
              areaItemType: AreaItemType.FIELDS,
              config: {
                ...field.measure,
                guid: field.measure.guid ?? generateGuid(),
                format:
                  field.measure.format ||
                  (measure?.defaultFormat?.formatType === "Numeric" ? measure?.defaultFormat : undefined),
                dateRange: field.measure.dateRange || DateRangeType.EndingBalance,
              },
            };
          } else {
            console.log(`Measure ${field.measure?.name} is not found in the measures' list`);
            return undefined;
          }
        }
        return tableField;
      })
      .filter((field): field is TableField => !!field);
    return tableField;
  } catch {
    return [];
  }
};

export const configurationToGrouping = (grouping: GroupingField[]) => {
  try {
    const groups = cloneDeep(grouping);
    return groups;
  } catch {
    return [];
  }
};

export const configurationToSettings = (settings: TabularGeneralSettings) => {
  return cloneDeep(settings);
};
