import { Grouping } from "../../../../shared/reporting/api/biClient.types";

export const getDateGroupingLabel = (grouping: Grouping | undefined) => {
  switch (grouping) {
    case Grouping.ByMonth: {
      return "by Month";
    }
    case Grouping.ByQuarter: {
      return "by Quarter";
    }
    case Grouping.ByYear: {
      return "by Year";
    }
    case undefined: {
      return "by Day";
    }
    default:
      return "";
  }
};

export const defaultDateGrouping = [Grouping.ByYear, Grouping.ByQuarter, Grouping.ByMonth, undefined];
