import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Box, Typography } from "@mui/material";
import { GridBasicGroupNode, GridColDef } from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import equal from "fast-deep-equal";
import { MutableRefObject } from "react";
import TooltipWrapper from "../../../../../../shared/components/TooltipWrapper";
import { MeasureCell, TabularDataCell } from "../../../../../../shared/reporting/api/biClient.types";
import { AnyObject } from "../../../../../../shared/types";
import { GroupTotal } from "../../../../../api/biApi.types";
import { ColumnField } from "../../../Types";
import { MeasureCell as MeasureCellComponent } from "../MeasureCell";
import { getGroupKeys } from "./getGroupKeys";

export type DimensionField = {
  guid: string;
  dimension?: ColumnField;
};

type MeasureColumnOptions = {
  field: {
    guid: string;
    title: string;
    hideAggregation?: boolean;
    isFailedToLoad: (fieldGuid: string) => boolean;
  };
  getFieldTitle: (guid: string) => string;
  fields: MutableRefObject<DimensionField[]>;
  groupTotals: MutableRefObject<GroupTotal[]>;
  grandTotals: MutableRefObject<AnyObject>;
  apiRef: MutableRefObject<GridApiPremium>;
  loading: MutableRefObject<boolean>;
};

export function buildMeasureColumn({
  field,
  getFieldTitle,
  fields,
  grandTotals,
  groupTotals,
  apiRef,
  loading,
}: MeasureColumnOptions): GridColDef {
  return {
    field: field.guid,
    headerName: field.title,
    minWidth: 175,
    groupable: false,
    aggregable: !field.hideAggregation,
    type: "string",
    align: "right",
    headerAlign: "right",
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
          {field.isFailedToLoad(field.guid) && (
            <TooltipWrapper title="The column could not be calculated.">
              <WarningRoundedIcon
                sx={(theme) => ({
                  color: theme.palette.warning.light,
                  fontSize: 16,
                })}
              />
            </TooltipWrapper>
          )}
          <Typography variant="subtitle2">{getFieldTitle(field.guid)}</Typography>
        </Box>
      );
    },
    valueGetter: (_, row, column) => {
      const cell = row?.data?.[column?.field] as TabularDataCell;
      return cell?.formattedValue;
    },
    renderCell: ({ id, row, aggregation, value, field: columnField }) => {
      let formattedValue = value;

      if (id) {
        let rowNode = apiRef.current.getRowNode(id);
        if (rowNode?.type === "footer") {
          rowNode = apiRef.current.getRowNode(rowNode.parent);
        }
        if (rowNode?.type === "pinnedRow") {
          rowNode = apiRef.current.getRowNode(rowNode.parent);
        }
        if (rowNode?.type === "group") {
          // if aggregation has undefined value it means it's need to be hidden
          if (field.hideAggregation === true || formattedValue === undefined) {
            return "";
          }
          if (loading.current) {
            return "...";
          }

          formattedValue = getMeasureCellValue(
            rowNode,
            columnField,
            fields.current,
            grandTotals.current,
            groupTotals.current,
            apiRef
          );
        }
      }

      const cell = row.data?.[columnField] as TabularDataCell;
      if (aggregation || cell?.drillDownId === undefined) {
        return <MeasureCellComponent variant={aggregation ? "subtitle2" : "body1"} value={formattedValue} />;
      }

      return (
        <MeasureCellComponent
          variant="body1"
          value={formattedValue}
          sx={{
            "&:hover": {
              cursor: "pointer",
              textDecoration: "underline",
            },
          }}
        />
      );
    },
  };
}

function getMeasureCellValue(
  rowNode: GridBasicGroupNode,
  fieldName: string,
  fields: { guid: string; dimension?: ColumnField }[],
  grandTotals: AnyObject,
  groupTotals: GroupTotal[],
  apiRef: React.MutableRefObject<GridApiPremium>
) {
  let value = "";
  if (rowNode.parent === null && rowNode.groupingKey === null) {
    const cell = grandTotals[fieldName] as TabularDataCell;
    value = cell?.formattedValue;
  } else {
    const filters = getGroupKeys(rowNode, fields, apiRef.current);
    const group = groupTotals.find((gt) => hasSameFilters(gt.filters, filters));
    if (group !== undefined) {
      const cell = group.values[fieldName] as TabularDataCell;
      value = cell?.formattedValue;
    }
  }
  return value;
}

function hasSameFilters(filters: Record<string, MeasureCell>, groupKeys: Record<string, Partial<MeasureCell>>) {
  if (filters === groupKeys) return true;
  if (filters === null || groupKeys === null) return false;
  if (Object.keys(filters).length !== Object.keys(groupKeys).length) return false;
  for (const key in groupKeys) {
    if (!equal(filters[key]?.value?.toString(), groupKeys[key]?.value?.toString())) {
      return false;
    }
  }
  return true;
}
