import { Avatar } from "@mui/material";
import EntriliaLogo from "../../../../shared/images/greylogo.png";
import { CellProperties, CellPropertyEnum } from "../../../../shared/reporting/api/biClient.types";

export default function DimensionStartIcon({ cell }: { cell: { properties?: CellProperties } | undefined }) {
  if (cell?.properties === undefined) return null;
  const startIconSrc = cell.properties[CellPropertyEnum.StartIcon];
  if (startIconSrc === undefined) return null;
  const logoUrl = startIconSrc ? startIconSrc : EntriliaLogo;
  return <Avatar src={logoUrl} variant="rounded" alt="logo" sx={{ width: 24, height: 24, mr: 0.5 }} />;
}
