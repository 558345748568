import objectHash from "object-hash";
import React from "react";
import { useSelector } from "react-redux";
import { selectRefreshToken } from "../../../../store/currentReportSlice";
import { selectDimensions } from "../../../../store/metaDataSlice";
import { GeneralField, ValueField } from "../../Types";
import getTrackedMeasureProperties from "../../utils/getTrackedMeasureProperties";
import { isTabularConfigurationValid } from "../../utils/isConfigurationValid";
import { useFieldsStateContext } from "../contexts/FieldsStateContext";
import { getColumns, getConditionsHash, getSorting } from "../utilities/gridStateHelper";
import { gridDataStateReducer, initialGridDataState } from "./gridDataState";
import { TableField } from "./TableField";
import useDataLoadingBuilder from "./useDataLoadingBuilder";

export type PreviewGridStateType = ReturnType<typeof useGridStateBuilder>;

export default function useGridStateBuilder() {
  const { conditionsArea, sortingArea, fieldsArea, groupingArea, settingsArea } = useFieldsStateContext();
  const dimensions = useSelector(selectDimensions);

  const [state, dispatch] = React.useReducer(gridDataStateReducer, initialGridDataState);
  const {
    loadData,
    loadGroupTotals,
    cancelDataLoading,
    cancelGroupTotalsLoading,
    failedMeasures,
    failedGroupTotalMeasures,
    handleInvalidConfiguration,
  } = useDataLoadingBuilder(dispatch);
  const refreshToken = useSelector(selectRefreshToken);

  const loading = state.loading > 0;
  const groupTotalsLoading = state.groupTotalsLoading > 0;

  const columns = React.useMemo(() => getColumns(fieldsArea.values, state.columns), [fieldsArea.values, state.columns]);
  const sorting = React.useMemo(
    () => getSorting(sortingArea.values, fieldsArea.values),
    [sortingArea.values, fieldsArea.values]
  );

  const conditionsHash = React.useMemo(
    () => getConditionsHash(conditionsArea.values, fieldsArea.values),
    [conditionsArea.values, fieldsArea.values]
  );
  const columnsHash = React.useMemo(() => objectHash(columns.map((c) => c.name).sort()), [columns]);
  const fieldsHash = React.useMemo(() => getFieldsHash(fieldsArea.values), [fieldsArea.values]);
  const groupingHash = React.useMemo(() => objectHash(groupingArea.values.map((v) => v.name)), [groupingArea.values]);
  const settingsHash = React.useMemo(
    () =>
      objectHash({
        hideBlankRows: settingsArea.settings.hideBlankRows,
        showGrandTotal: settingsArea.settings.showGrandTotal,
      }),
    [settingsArea.settings]
  );

  const configurationValidity = React.useMemo(() => {
    return isTabularConfigurationValid(conditionsArea.values, fieldsArea.values, dimensions);
  }, [conditionsArea.values, fieldsArea.values, dimensions]);

  const isConfigurationValidRef = React.useRef(configurationValidity.valid);
  isConfigurationValidRef.current = configurationValidity.valid;

  React.useEffect(() => {
    if (configurationValidity.valid) {
      loadData(refreshToken);
    } else {
      handleInvalidConfiguration();
      cancelDataLoading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditionsHash, columnsHash, fieldsHash, settingsHash, refreshToken, configurationValidity.valid]);

  React.useEffect(() => {
    if (groupingArea.values.length > 0 && configurationValidity.valid) loadGroupTotals();
    else cancelGroupTotalsLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupingHash, configurationValidity.valid]);

  return {
    rows: state.rows,
    columns,
    sorting,
    groupTotals: state.groupTotals,
    grandTotals: state.grandTotals,
    totalCount: state.totalCount,
    loading,
    groupTotalsLoading,
    error: state.error,
    configurationValidity,
    failedMeasures,
    failedGroupTotalMeasures,
  };
}

function getFieldsHash(fields: TableField[]) {
  const measures = fields.map((v) => v.measure).filter((m): m is ValueField => !!m);
  const allMeasures = getTrackedMeasureProperties(measures).sort(sortMeasure);

  const dimensions = fields.map((v) => v.dimension).filter((d): d is GeneralField => !!d);
  const allDimensions = dimensions.map((d) => ({
    showLogoIcon: d.config.showLogoIcon,
    format: d.config.format,
    grouping: d.config.grouping,
    customLabel: d.config.customLabel,
    suppressEmptyValues: d.config.suppressEmptyValues,
  }));

  const hash = objectHash({ allMeasures, allDimensions });
  return hash;
}

function sortMeasure(a: { guid: string }, b: { guid: string }) {
  if (a.guid > b.guid) return 1;
  if (a.guid < b.guid) return -1;
  return 0;
}
