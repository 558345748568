import { Box, Divider, Grid, Tab, Tabs } from "@mui/material";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import ConditionsDropFieldsContainer from "./conditions/ConditionsDropFieldsContainer";
import FormatContainer from "./format/FormatContainer";
import MeasuresDropFieldsContainer from "./measures/MeasuresDropFieldsContainer";
import ColumnsDropFieldsContainer from "./columns/ColumnsDropFieldsContainer";
import SortFieldsContainer from "../../common/sorting/SortFieldsContainer";
import SettingsContainer from "./settings/SettingsContainer";
import { BuilderContext } from "../types";
import RowsDropFieldsContainer from "./rows/RowsDropFieldsContainer";
import Visualization from "../../../common/Visualization";
import { ReportType } from "../../../../../shared/reporting/api/biClient.types";
import { useDispatch, useSelector } from "react-redux";
import { currentReportActions, selectCurrentReport } from "../../../../store/currentReportSlice";
import { ColumnField, RowField } from "../../Types";
import ScrollableFlexContainer from "../../../../../shared/components/ScrollableFlexContainer";

export const TableComponent = () => {
  const { rowsArea, columnsArea, valuesArea, conditionsArea, sortArea } = useContext(BuilderContext);
  const dispatch = useDispatch();
  const report = useSelector(selectCurrentReport);

  const [selectedTab, setSelectedTab] = useState(0);

  const columnsRef = useRef<ColumnField[]>([]);
  columnsRef.current = columnsArea.values;

  const rowsRef = useRef<RowField[]>([]);
  rowsRef.current = rowsArea.values;

  const availableFields = useMemo(() => {
    const result = rowsArea.values
      .concat(columnsArea.values)
      .filter((f) => !sortArea.values.find((v) => v.meta.name === f.meta.name));
    return result;
  }, [rowsArea.values, columnsArea.values, sortArea.values]);

  const onReportTypeChanged = useCallback(
    (reportType: ReportType) => {
      dispatch(
        currentReportActions.updateReport({
          reportType,
          configuration: {
            conditions: report?.configuration.conditions || [],
            columns: [],
            fields: [],
            rows: [],
            sort: [],
            values: [],
            grouping: [],
            settings: {},
          },
        })
      );
      dispatch(currentReportActions.refreshSession());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [report]
  );

  return (
    <Box sx={{ display: "flex", flex: 1, flexDirection: "column", gap: "1rem", mt: "1rem" }}>
      <Grid container px="1.3rem">
        <Visualization type={ReportType.Pivot} onSelect={onReportTypeChanged} />

        <Tabs value={selectedTab} sx={{ borderBottom: "none" }} onChange={(_, value) => setSelectedTab(value)}>
          <Tab label="Fields" />
          <Tab label="Settings" />
          <Tab label="Format" />
        </Tabs>
      </Grid>
      <Divider sx={{ mt: -2 }} />
      {selectedTab === 0 && (
        <ScrollableFlexContainer
          scrollContainerSx={{ display: "flex", flexDirection: "column", gap: "1.5rem", px: "1.3rem" }}
        >
          <ConditionsDropFieldsContainer area={conditionsArea} disableEqualitySelection={true} />
          <RowsDropFieldsContainer />
          <ColumnsDropFieldsContainer />
          <MeasuresDropFieldsContainer
            conditionsArea={conditionsArea}
            valuesArea={valuesArea}
            fields={rowsArea.values.concat(columnsArea.values)}
          />
          <SortFieldsContainer area={sortArea} availableFields={availableFields} />
        </ScrollableFlexContainer>
      )}

      {selectedTab === 1 && <SettingsContainer />}
      {selectedTab === 2 && <FormatContainer />}
    </Box>
  );
};

export default TableComponent;
