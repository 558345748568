import { Typography } from "@mui/material";
import { formatDateShort } from "../../shared/utilities/dateUtils";
import { ItemDataType, MetaDescriptorBase } from "../api/biApi.types";
import { GeneralField } from "../components/builder/Types";

export const formatDate = (date: Date) => formatDateShort(date);

export const parseDate = (dateStr: string) => {
  return new Date(dateStr);
};

export const isDateField = (field: MetaDescriptorBase) => {
  return field.type === ItemDataType.Date;
};

export const getFieldCaption = (
  field: { config: { customLabel?: string; systemLabel?: string }; meta: { caption: string } } | undefined
) => {
  if (field === undefined) return "No Name";
  return field.config.customLabel || field.config.systemLabel || field.meta.caption;
};

export const styleCaption = (field: GeneralField, value: string | undefined) => {
  if (!field.config.style?.fontStyle) {
    return <Typography component={"span"}>{value}</Typography>;
  }
  const el = (
    <Typography
      component="span"
      sx={{
        fontWeight: field.config.style.fontStyle.indexOf("bold") > -1 ? "bold" : "inherit",
        textDecoration: field.config.style.fontStyle.indexOf("underlined") > -1 ? "underline" : "inherit",
        fontStyle: field.config.style.fontStyle.indexOf("italic") > -1 ? "italic" : "inherit",
      }}
    >
      {value}
    </Typography>
  );
  return el;
};

export function insertItemAt<T>(array: T[], item: T, index: number): T[] {
  if (index < 0 || index > array.length) {
    throw new Error("Index is out of range");
  }

  return [...array.slice(0, index), item, ...array.slice(index)];
}

export function isEmptyObject(obj: Record<string, unknown>): boolean {
  return Object.keys(obj).length === 0;
}

export function areAllFieldsEmpty(obj: Record<string, unknown>): boolean {
  return Object.values(obj).every((value) => {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else if (typeof value === "object" && value !== null) {
      return isEmptyObject(value as Record<string, unknown>);
    }
    return false;
  });
}
