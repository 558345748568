import {
  AmountType,
  CalculateByField,
  DateRangeType,
  IrrCashFlowFrequency,
  MeasureConfigurationType,
  NumberFormatterOptions,
} from "../../../../shared/reporting/api/biClient.types";
import { MeasureUnit } from "../../../api/biApi.types";
import { ValueField } from "../Types";
import { getMeasureAmountTypeField, getMeasureCalculateByField } from "./isConfigurationValid";

const getTrackedMeasureProperties = (measures: ValueField[]): MeasureInfoTracked[] => {
  return measures.map(
    (measure): MeasureInfoTracked => ({
      guid: measure.config.guid,
      dateRange: measure.config.dateRange,
      standalone: measure.config.standalone,
      calculateByField: getMeasureCalculateByField(measure),
      units: measure.meta.units,
      blankZero: measure.config.blankZero,
      reverseSign: measure.config.reverseSign,
      customLabel: measure.config.customLabel,
      format: measure.config.format,
      cashFlowFrequency:
        measure.config.type === MeasureConfigurationType.Irr ? measure.config.cashFlowFrequency : undefined,
      amountType: getMeasureAmountTypeField(measure),
      suppressEmptyValues: measure.config.suppressEmptyValues,
    })
  );
};

export type MeasureInfoTracked = {
  guid: string;
  dateRange: DateRangeType;
  standalone: boolean | undefined;
  calculateByField: CalculateByField | undefined;
  units: {
    [key: string]: MeasureUnit;
  };
  blankZero: boolean | undefined;
  reverseSign: boolean | undefined;
  customLabel: string | undefined;
  format: NumberFormatterOptions | undefined;
  cashFlowFrequency: IrrCashFlowFrequency | undefined;
  amountType: AmountType;
  suppressEmptyValues: boolean | undefined;
};

export default getTrackedMeasureProperties;
