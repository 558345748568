import { ConditionField, EMPTY_CROSSFILTERED_CONDITION_VALUES } from "../../Types";
import React from "react";
import objectHash from "object-hash";
import equal from "fast-deep-equal";
import { ConditionDescriptor } from "../../../../../shared/reporting/api/biClient.types";
import { FilteredDictionaryRequest, FilteredDictionaryResponse, ItemDataType } from "../../../../api/biApi.types";
import { ApiResponse } from "../../../../../shared/api/types";
import { defined } from "../../../../../shared/utilities/typeHelper";
import biClient, { CancellationToken } from "../../../../api/biApi";

export default function useCrossFiltering(area: {
  values: ConditionField[];
  updateItem: (field: ConditionField, changes: Partial<ConditionField>) => void;
}) {
  const [changedConditions, setChangedConditions] = React.useState<ChangedConditionsType>();

  const changedConditionsRef = React.useRef(changedConditions);
  const cancellationRef = React.useRef<CancellationToken>();
  const updateItemRef = React.useRef(area.updateItem);
  updateItemRef.current = area.updateItem;

  const actualChangedConditions = React.useMemo((): ChangedConditionsType => {
    const ids = getConditionIds(area.values);
    const newConditions = findChangedConditions(changedConditions?.ids, ids);
    return { ids, changedConditions: newConditions };
  }, [area.values, changedConditions]);

  const handleError = React.useCallback(
    (_: unknown, conditionsToProceed: ChangedConditionsType) => {
      // do not proceed further logic if conditions have been changed while executing request
      if (!areValuesEqual(changedConditionsRef.current, conditionsToProceed)) return;
      actualChangedConditions.changedConditions.forEach((cc) =>
        updateItemRef.current(cc.field, {
          crossFilter: { loading: false, toRefresh: false, values: [] },
        })
      );
    },
    [actualChangedConditions]
  );

  const handleResponse = React.useCallback(
    (response: ApiResponse<FilteredDictionaryResponse>, conditionsToProceed: ChangedConditionsType) => {
      // do not proceed further logic if conditions have been changed while executing request
      if (!areValuesEqual(changedConditionsRef.current, conditionsToProceed)) {
        return;
      }
      if (!response.success) handleError(response.error, conditionsToProceed);
      else {
        const field = defined(conditionsToProceed.changedConditions[0]).field;
        const values = response.data.values.length === 0 ? EMPTY_CROSSFILTERED_CONDITION_VALUES : response.data.values;
        updateItemRef.current(field, {
          crossFilter: {
            loading: false,
            toRefresh: false,
            values,
          },
          config: {
            guid: field.config.guid,
            parameter: field.config.parameter,
            mandatory: field.config.mandatory,
            filter: {
              ...field.config.filter,
              values: (field.config.filter?.values || []).filter(
                (value) => values !== EMPTY_CROSSFILTERED_CONDITION_VALUES && values.includes(value)
              ),
            } as ConditionDescriptor,
          },
        });
      }
    },
    [handleError]
  );

  const processChangedConditions = React.useCallback(
    (conditionsToProceed: ChangedConditionsType) => {
      if (conditionsToProceed.changedConditions.length === 0) {
        return;
      }
      const condition = conditionsToProceed.changedConditions[0];
      if (condition === undefined) {
        return;
      }
      const payload = buildNextItemConditions(condition, conditionsToProceed.ids);
      if (cancellationRef.current !== undefined) {
        cancellationRef.current.cancel();
      }
      if (payload.conditions.length === 0) {
        updateItemRef.current(condition.field, {
          crossFilter: {
            loading: false,
            toRefresh: false,
            values: [],
          },
        });
        return;
      }
      updateItemRef.current(condition.field, {
        crossFilter: { ...condition.field.crossFilter, loading: true, toRefresh: false },
      });
      cancellationRef.current = biClient.getFilteredDictionary(
        payload,
        (result) => handleResponse(result, conditionsToProceed),
        (error) => handleError(error, conditionsToProceed)
      );
    },
    [handleResponse, handleError]
  );

  React.useEffect(() => {
    changedConditionsRef.current = changedConditions;
    if (equal(changedConditions?.ids, actualChangedConditions.ids)) return;
    setChangedConditions(actualChangedConditions);
    actualChangedConditions.changedConditions.forEach((cc) =>
      updateItemRef.current(cc.field, {
        crossFilter: { loading: false, toRefresh: true, values: cc.field.crossFilter?.values },
      })
    );
    processChangedConditions(actualChangedConditions);
  }, [actualChangedConditions, changedConditions, setChangedConditions, processChangedConditions]);
}

export function getConditionIds(conditions: ConditionField[]): ConditionId[] {
  const onlyTextDimensions = conditions.filter((c) => c.meta.type === ItemDataType.General);
  const validConditions = getValidConditions(onlyTextDimensions);
  return validConditions.map((field) => ({ hash: getConditionFieldHash(field), field }));
}

export function getConditionFieldHash(field: ConditionField) {
  return objectHash({ id: field.config.guid, filter: field.config.filter?.values || [] });
}

export function findChangedConditions(oldIds: ConditionId[] | undefined, newIds: ConditionId[]): ConditionId[] {
  if (oldIds === undefined) return newIds;
  const getConditionsFromActive = () => {
    const loadingConditionIndex = newIds.findIndex(
      (id) => id.field.crossFilter?.loading === true || id.field.crossFilter?.toRefresh === true
    );
    // if a condition is already loading or waiting to load, return all conditions starting from that one
    if (loadingConditionIndex > -1) {
      return newIds.slice(loadingConditionIndex);
    }
    return undefined;
  };

  for (let index = 0; index < newIds.length; index++) {
    const oldId = oldIds[index];
    const newId = newIds[index];
    if (oldId?.hash !== newId?.hash) {
      // if there is only one condition then nothing to load
      if (newIds.length === 1) return [];
      // when filter condition is changed for the last condition then nothing to load
      if (index === newIds.length - 1 && oldId !== undefined) {
        return getConditionsFromActive() || [];
      }
      // if condition reordered or one of conditions removed
      if (oldId?.field.config.guid !== newId?.field.config.guid) {
        return getConditionsFromActive() || newIds.slice(index);
      }
      return newIds.slice(index + 1);
    }
  }
  if (newIds.every((id) => !id.field.crossFilter?.loading)) {
    const firstFieldWithToRefreshState = newIds.findIndex((id) => id.field.crossFilter?.toRefresh === true);
    if (firstFieldWithToRefreshState > -1) {
      return newIds.slice(firstFieldWithToRefreshState);
    }
  }
  return [];
}

function getValidConditions(conditions: ConditionField[]): ConditionField[] {
  return conditions
    .filter((c) => {
      if (c.invalid) return false;
      if (c.hasLinks) return false;
      if (c.meta.type !== ItemDataType.General) return false;
      return true;
    })
    .filter((f): f is ConditionField => !!f);
}

function buildNextItemConditions(
  currentCondition: ConditionId,
  crossFilterableIds: ConditionId[]
): FilteredDictionaryRequest {
  const result: FilteredDictionaryRequest = {
    attributeName: currentCondition.field.meta.name,
    conditions: [],
  };
  for (let index = 0; index < crossFilterableIds.length; index++) {
    const element = defined(crossFilterableIds[index]);
    if (element.hash === currentCondition.hash) break;
    if (element.field.config.filter !== undefined && element.field.config.filter.values.length > 0) {
      result.conditions.push(element.field.config.filter);
    }
  }

  return result;
}

function areValuesEqual(left: ChangedConditionsType | undefined, right: ChangedConditionsType) {
  if (
    equal(
      left?.ids?.map((i) => i.hash),
      right.ids?.map((i) => i.hash)
    )
  ) {
    return true;
  }
  const currentConditionId = right.ids.findIndex((id) => id.hash === defined(right.changedConditions[0]).hash);
  if (currentConditionId > -1) {
    const leftConditionsTillCurrent = left?.ids.slice(0, currentConditionId);
    const rightConditionsTillCurrent = right?.ids.slice(0, currentConditionId);
    return equal(
      leftConditionsTillCurrent?.map((i) => i.hash),
      rightConditionsTillCurrent.map((i) => i.hash)
    );
  }
  return false;
}

export type ConditionId = {
  hash: string;
  field: ConditionField;
};

export type ChangedConditionsType = {
  ids: ConditionId[];
  changedConditions: ConditionId[];
};
