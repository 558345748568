import { Button, Grid, Popover, Typography } from "@mui/material";
import HorizontalFill from "../../../shared/components/HorizontalFill";

interface Props {
  open: boolean;
  anchorEl: HTMLElement | undefined;
  onSave: () => void;
  onContinue: () => void;
  onClose: () => void;
}

export default function UnsavedChangesPopover({ open, anchorEl, onSave, onContinue, onClose }: Props) {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 1px 2px rgba(0,0,0,0.32))",
          maxWidth: "310px",
          mt: 1,
          ml: -0.4,
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            left: 0,
            width: 12,
            height: 12,
            bgcolor: "background.paper",
            transform: "translateY(-50%) translateX(100%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      sx={{
        minHeight: "45px",
      }}
    >
      <Grid container sx={{ flexDirection: "column", p: 2, gap: 1 }}>
        <Typography variant="subtitle1">Unsaved Changes</Typography>
        <Typography color="secondary">
          Please note that the data in this report has not been saved. If you make changes to the report visualization,
          all unsaved data will be lost.
        </Typography>
        <Grid container sx={{ gap: 1, pt: 1 }}>
          <HorizontalFill />
          <Button variant="outlined" onClick={onSave}>
            Save Changes...
          </Button>
          <Button variant="contained" onClick={onContinue}>
            Continue
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
}
